import React, { useEffect, useState, useContext } from "react";
import {
  Layout,
  Input,
  Avatar,
  Spin,
  Popover,
  Divider,
  Tag,
  Col,
  Row,
  Space,
  Button,
} from "antd";

import {
  Link,
  useNavigate,
  useLocation,
  Switch,
  Route,
  Outlet,
} from "react-router-dom";

import { MyContext } from "../Context";

import { ReactComponent as Home_img } from "../img/nav-icons/Home.svg";
import { ReactComponent as Saved_img } from "../img/nav-icons/Saved.svg";
import { ReactComponent as Chats_img } from "../img/nav-icons/Chats.svg";
import { ReactComponent as Conn_img } from "../img/nav-icons/Connections.svg";
import { ReactComponent as Profile_img } from "../img/nav-icons/Profile.svg";
import { ReactComponent as Subs_img } from "../img/nav-icons/Subscriptions.svg";
import { ReactComponent as Vaults_img } from "../img/nav-icons/Vaults.svg";
import { ReactComponent as Drops_img } from "../img/nav-icons/Drops.svg";
import { ReactComponent as Interactions_img } from "../img/nav-icons/Interactions.svg";

const LoggedOutMenuSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { userEmail } = useContext(MyContext);

  useEffect(() => {
    setSelectedMenu(window.location.href.split("/").pop());
  }, [window.location.href.split("/").pop()]);

  return (
    <Col
      span={4}
      style={{
        // background: "#E5E5E5",
        padding: "10px",
        paddingTop: "20px",
        height: "90vh",
        borderRight: "0.5px solid #EDEDED",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Link to="/home">
          <div
            onMouseOver={() => setSelectedMenu("home")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== ""
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === ""
                  ? "#DEF2FF"
                  : selectedMenu === "home"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Home_img
              fill={
                window.location.href.split("/").pop() === ""
                  ? "#009EFC"
                  : selectedMenu === "home"
                  ? "#009EFC"
                  : "#999999"
              }
              stroke="none"
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === ""
                    ? "#009EFC"
                    : selectedMenu === "home"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Pulse
            </div>
          </div>
        </Link>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("saved")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "saved"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "saved"
                  ? "#DEF2FF"
                  : selectedMenu === "saved"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Saved_img
              // fill={selectedMenu === "saved" ? "#009EFC" : "#999999"}
              stroke={
                window.location.href.split("/").pop() === "saved"
                  ? "#009EFC"
                  : selectedMenu === "saved"
                  ? "#009EFC"
                  : "#999999"
              }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "saved"
                    ? "#009EFC"
                    : selectedMenu === "saved"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Saved
            </div>
          </div>
        </Link>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("brain")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "brain"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "brain"
                  ? "#DEF2FF"
                  : selectedMenu === "brain"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Saved_img
              // fill={selectedMenu === "saved" ? "#009EFC" : "#999999"}
              stroke={
                window.location.href.split("/").pop() === "brain"
                  ? "#009EFC"
                  : selectedMenu === "brain"
                  ? "#009EFC"
                  : "#999999"
              }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "brain"
                    ? "#009EFC"
                    : selectedMenu === "brain"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Brain
            </div>
          </div>
        </Link>
        <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("chats")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "chats" ? "#DEF2FF" : "",
          }}
        >
          <Chats_img
            fill={selectedMenu === "chats" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "chats" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Chats
          </div>
        </div>

        <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("conn")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "conn" ? "#DEF2FF" : "",
          }}
        >
          <Conn_img
            fill={selectedMenu === "conn" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "conn" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Connections
          </div>
        </div>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("profile")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "profile"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background: location.pathname.includes(
                localStorage.getItem("actual_username")
              )
                ? ""
                : localStorage.getItem("selected_user_email") === userEmail
                ? ""
                : "",
            }}
          >
            <Profile_img
              fill={selectedMenu === "profile" ? "#009EFC" : "#999999"}
              stroke="none"
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color: selectedMenu === "profile" ? "#009EFC" : "#999999",
                paddingLeft: "10px",
              }}
            >
              Profile
            </div>
          </div>
        </Link>
        <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("subs")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "subs" ? "#DEF2FF" : "",
          }}
        >
          <Subs_img
            fill={selectedMenu === "subs" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "subs" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Subscriptions
          </div>
        </div>

        <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("vaults")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "vaults" ? "#DEF2FF" : "",
          }}
        >
          <Vaults_img
            fill={selectedMenu === "vaults" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "vaults" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Vaults
          </div>
        </div>

        <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("drops")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "drops" ? "#DEF2FF" : "",
          }}
        >
          <Drops_img
            fill={selectedMenu === "drops" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "drops" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Drops
          </div>
        </div>

        <div
          onClick={(e) => navigate("/login")}
          onMouseOver={() => setSelectedMenu("interactions")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "interactions" ? "#DEF2FF" : "",
          }}
        >
          <Interactions_img
            fill={selectedMenu === "interactions" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "interactions" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Interactions
          </div>
        </div>
      </Space>
    </Col>
  );
};

export default LoggedOutMenuSidebar;
