import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Row, Col, Input, Button, Form, message, Icon, Modal } from "antd";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../config";
import { MyContext } from "../Context";
import isEmail from "validator/lib/isEmail";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { gql } from "apollo-boost";

const GET_USER = gql`
  query ($email: ID!) {
    get_user(data: { email: $email }) {
      id
      username
      name
      bio
      email
      profile_image
      cover_image
    }
  }
`;

const Login = () => {
  const value = useContext(MyContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [rUsername, setRusername] = useState("");
  const [rEmail, setRemail] = useState("");
  const [rPassword, setRpassword] = useState("");
  const [rPassword1, setRpassword1] = useState("");
  const [visibleVerify, setVisibleVerify] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  const handleLogin = () => {
    if (isEmail(username) && password !== "") {
      // message.loading("Authenticating...");
      setLoading(true);
      let authenticationData = {
        Username: username,
        Password: password,
      };

      let authdet = new AuthenticationDetails(authenticationData);

      let userData = {
        Username: username,
        Pool: userPool,
      };

      let user = new CognitoUser(userData);

      user.authenticateUser(authdet, {
        onSuccess: function (result) {
          setLoading(false);
          // console.log(result, "jhvdjqddqwdqd");
          message.destroy();
          message.success("Login Successfull");
          let accessToken = result.accessToken.jwtToken;
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("user_account", username);
          localStorage.setItem("loginObj", JSON.stringify(result));

          //   setTimeout(() => {
          //     value.setLogin(true);
          //   }, 1000);
          var idtoken = result.getIdToken().getJwtToken();
          //idtoken.client_id = client_id;
          localStorage.setItem("token", idtoken);
          navigate("/");
          window.location.reload();
        },

        onFailure: function (err) {
          localStorage.removeItem("token");
          console.log(err);
          //   setTimeout(() => {
          //     value.setLogin(false);
          //   }, 2000);
          message.destroy();
          message.error(err.message, 2);
          if (err.message === "User is not confirmed.") {
            setVisibleVerify(true);
          }
        },
      });
    } else {
      message.destroy();
      message.error("Please enter Email & password");
    }
  };

  const handleSignup = () => {
    if (
      rUsername !== "" &&
      rEmail !== "" &&
      rPassword !== "" &&
      rPassword1 !== ""
    ) {
      if (isEmail(rEmail)) {
        if (rPassword === rPassword1) {
          message.loading("Signing you up...");
          Axios.post(`https://gxauth.apimachine.com/gx/user/signup`, {
            username: rUsername,
            email: rEmail,
            password: rPassword,
            ref_affiliate: "1",
            account_type: "Personal",
            signedup_app: "Brain",
          }).then((res) => {
            if (res.data.status === true) {
              // message.destroy();
              // message.success("Successfully Registered, Verify to continue.");
              setVisibleVerify(true);
            } else {
              message.destroy();
              message.error(res.data.message);
            }
          });
        }
      } else {
        message.error("invalid email");
      }
    } else {
      message.error("fill all fields");
    }
  };

  const handleVerify = () => {
    if (verificationCode !== "" && isEmail(rEmail)) {
      Axios.post(`https://gxauth.apimachine.com/gx/user/confirm`, {
        email: rEmail,
        code: verificationCode,
      }).then((res) => {
        if (res.data.status) {
          setVisibleVerify(false);
          message.destroy();
          message.success(
            "Your Account gas been verified. Please login to continue."
          );
        }
      });
    }
  };

  return (
    <>
      <Row>
        <Col
          span={16}
          // className="side-bg"
          style={{
            height: "100vh",
            background: "#009EFC",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={require("../img/Vector.png")} alt="" />
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
            }}
          >
            <img
              src={require("../img/Group4.svg")}
              alt=""
              style={{ width: "100%" }}
            />
            <br />
            <br />
            <form onSubmit={handleLogin} style={{ width: "100%" }}>
              <Input
                style={{ padding: "0px 30px" }}
                className="login-input"
                placeholder="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <br /> <br />
              <Input
                style={{ padding: "0px 30px" }}
                className="login-input"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </form>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "30px",
            }}
          >
            <Button
              loading={loading ? true : false}
              // type="submit"
              type="primary"
              className="login-button"
              onClick={handleLogin}
            >
              Login
            </Button>
            <br />
            <Link to="/getstarted/affiliate" className="signup-button">
              <Button
                className="signup-button"
                // loading={loading ? true : false}
                // type="submit"
                type="ghost"
              >
                Get Started
              </Button>
            </Link>
            <br />
            <br />
            <Link to="/getstarted/preregistered">
              <div className="already-registered">
                Click Here If You Were Pre-Registered
              </div>
            </Link>
          </div>

          {/* <div
            style={{
              backgroundColor: "#005166",
              display: "flex",
              padding: "25px",
            }}
          >
           
            <Input
              valur={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              placeholder="E M A I L"
              className="underline-input"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Input
              valur={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="P A S S W O R D"
              className="underline-input"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
            <Icon
              type="login"
              style={{
                cursor: "pointer",
                fontSize: "25px",
                fontWeight: "bold",
                color: "white",
              }}
              onClick={handleLogin}
            />
          </div>
          <div style={{ padding: "35px" }}>
            <h1
              style={{
                color: "#306AAE",
                fontWeight: "bold",
                letterSpacing: "6px",
                paddingLeft: "8px",
              }}
            >
              GET STARTED
            </h1>
            <br />
            <Input
              value={rUsername}
              onChange={(e) => setRusername(e.target.value)}
              type="text"
              placeholder="U S E R N A M E"
              className="underline-input1"
              style={{ marginBottom: "30px" }}
            />

            <Input
              value={rEmail}
              onChange={(e) => setRemail(e.target.value)}
              type="text"
              placeholder="E M A I L"
              className="underline-input1"
              style={{ marginBottom: "30px" }}
            />

            <Input
              value={rPassword}
              onChange={(e) => setRpassword(e.target.value)}
              type="password"
              placeholder="P A S S W O R D"
              className="underline-input1"
              style={{ marginBottom: rPassword === "" ? "10px" : "" }}
            />
            <div style={{ marginBottom: "20px", textAlign: "right" }}>
              {rPassword !== "" ? (
                strongRegex.test(rPassword) ? (
                  <span style={{ color: "green" }}>✔ Strong Password</span>
                ) : (
                  <span style={{ color: "red" }}>
                    ❌ Password is not strong, should contain a Capital Letter,
                    a special charecter &
                  </span>
                )
              ) : (
                ""
              )}
            </div>

            <Input
              value={rPassword1}
              onChange={(e) => setRpassword1(e.target.value)}
              type="password"
              placeholder="V E R I F Y  P A S S W O R D"
              className="underline-input1"
            />
          </div>
          <div
            onClick={handleSignup}
            style={{
              backgroundColor: "#005166",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              cursor: "pointer",
            }}
          >
            <h1
              style={{
                margin: "0px",
                color: "white",
                fontWeight: "bold",
                fontSize: "19px",
              }}
            >
              C O N N E C T
            </h1>
          </div> */}
        </Col>
      </Row>

      {/* Verify with OTP Modal */}

      <Modal
        centered
        closable={false}
        // title="Basic Modal"
        visible={visibleVerify}
        onCancel={(e) => setVisibleVerify(false)}
        footer={null}
        // bodyStyle={{ padding: "0px" }}
      >
        <h2>Signup Verification</h2>
        <p>
          You have received a verification code in your registered Email. Please
          type both Email and the code and click on verify to proceed
        </p>
        <Input
          placeholder="Enter Registered Email"
          style={{ marginBottom: "10px" }}
          type="text"
          value={rEmail}
          onChange={(e) => setRemail(e.target.value)}
        />
        <Input
          placeholder="Enter Verification Code"
          style={{ marginBottom: "10px" }}
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <Button block type="primary" size="large" onClick={handleVerify}>
          V E R I F Y
        </Button>
      </Modal>
    </>
  );
};

export default Login;
