import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
// import S3 from "aws-s3";
import AWS from "aws-sdk";
import { customAlphabet } from "nanoid";
import { gql } from "apollo-boost";
import {
  Row,
  Col,
  Card,
  Avatar,
  Icon,
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Spin,
  Badge,
  Tag,
  Popover,
  Menu,
  Dropdown,
  Drawer,
  message,
} from "antd";
import prof from "../img/user.svg";
import lockedImg from "../img/blurry.jpeg";
import ProfileCard from "./ProfileCard";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";

import { MyContext } from "../Context";

import {
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  LikeOutlined,
  DeleteOutlined,
  SettingOutlined,
  PlayCircleOutlined,
  LockFilled,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  CameraOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const MY_POSTS = gql`
  query ($user_id: ID!) {
    get_my_posts(data: { user_id: $user_id }) {
      id
      title
      body
      timestamp
      like_count
      post_tier {
        id
        name
      }
      comments {
        text
      }
      author {
        id
        name
        username
        profile_image
        email
      }
      comment_count
      liked_users {
        id
        username
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const NEWS_FEED = gql`
  query ($user_id: ID!) {
    get_my_home_posts(data: { user_id: $user_id }) {
      id
      title
      body
      locked
      post_tier {
        id
        name
      }
      timestamp
      author {
        username
        id
        email
      }
      comments {
        text
        author {
          id
          username
        }
      }
      liked_users {
        id
        email
        username
      }
      comment_count
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const LIKE_POST = gql`
  mutation lkpost(
    $user_id: ID!
    $id: ID!
    $email: String!
    $dislikeflag: Boolean!
  ) {
    like_dislike_for_post(
      data: { user_id: $user_id, post_id: $id, email: $email }
      dislike: $dislikeflag
    )
  }
`;

const CREATE_COMMENT = gql`
  mutation cmt($user_id: ID!, $id: ID!, $email: String!, $text: String!) {
    create_comment(
      data: { user_id: $user_id, post_id: $id, email: $email, text: $text }
    ) {
      like_count
      text
      id
    }
  }
`;

const GET_COMMENT = gql`
  query getcmt($email: ID!, $id: ID!) {
    get_comments_of_a_post(data: { email: $email, post_id: $id }) {
      id
      like_count
      text
      timestamp
      author {
        name
        profile_image
      }
    }
  }
`;

const DELETE_POST = gql`
  mutation delpost($user_id: ID!, $email: String!, $postId: ID!) {
    delete_post(data: { user_id: $user_id, email: $email, post_id: $postId })
  }
`;

const SAVE_POST = gql`
  mutation savepost($user_id: ID!, $postId: ID!) {
    add_to_bookmark(data: { user_id: $user_id, post_id: $postId })
  }
`;

const UNSAVE_POST = gql`
  mutation savepost($user_id: ID!, $postId: ID!) {
    remove_from_bookmark(data: { user_id: $user_id, post_id: $postId })
  }
`;

const UPDATE_POST = gql`
  mutation editPost(
    $user_id: ID!
    $postId: ID!
    $email: String!
    $title: String!
    $body: String!
  ) {
    edit_post(
      data: {
        user_id: $user_id
        post_id: $postId
        email: $email
        title: $title
        body: $body
      }
    ) {
      title
      body
      timestamp
      id
    }
  }
`;

const MY_SAVED_POSTS = gql`
  query ($user_id: ID!) {
    get_my_bookmarked_posts(data: { user_id: $user_id }) {
      id
      title
      body
      timestamp
      like_count
      post_tier {
        id
        name
      }
      comments {
        text
      }
      author {
        id
        name
        username
        profile_image
        email
      }
      comment_count
      liked_users {
        id
        username
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const PostComponent = ({ item, index, userData }) => {
  const {
    tokenValid,
    mySavedPosts,
    getPostsOfTrend,
    setTrendPostCount,
    setSelectedTrend,
    setTrendClicked,
  } = useContext(MyContext);
  // console.log("postitem", item);

  const postBodyRef = useRef();
  const postBodyRefVideo = useRef();
  var savedRange;

  const nanoid = customAlphabet("23456789abcdefghijklmnopqrstuvwxyz", 15);
  const S3_BUCKET = "connections-app-dev";

  const [showCommentArr, setShowCommentArr] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const [postComments, setPostComments] = useState([]);

  const [likedUsers, setLikedUsers] = useState([]);
  const [userComments, setUserComments] = useState(item);
  const [postBodyHeight, setPostBodyHeight] = useState(0);

  const [visibleProfileCard, setVisibleProfileCard] = useState(false);

  const [likeDislikePost, likeDislikePostData] = useMutation(LIKE_POST);
  const [commentOnPost, commentOnPostData] = useMutation(CREATE_COMMENT);
  const [deletePost, deletePostData] = useMutation(DELETE_POST);
  const [savePost, savePostData] = useMutation(SAVE_POST);
  const [unsavePost, unsavePostData] = useMutation(UNSAVE_POST);
  const [getComment, getCommentData] = useLazyQuery(GET_COMMENT);

  const [visibleEditPost, setVisibleEditPost] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedBody, setEditedBody] = useState("");
  const [editLoading, setEditLoading] = useState(false);

  const [myVideoURL, setMyVideoURL] = useState("");

  const editBodyRef = useRef();

  const [updatePost, updatePostData] = useMutation(UPDATE_POST);

  const saveSelection = () => {
    if (window.getSelection) {
      //non IE Browsers
      savedRange = window.getSelection().getRangeAt(0);
    } else if (document.selection) {
      //IE
      savedRange = document.selection.createRange();
    }
  };

  useEffect(() => {
    if (deletePostData.data) {
      message.destroy();
      message.success("Post Deleted");
    }
  }, [deletePostData]);

  const restoreSelection = () => {
    // isInFocus = true;
    // bodyRef.current.focus();
    if (savedRange != null) {
      if (window.getSelection) {
        //non IE and there is already a selection
        var s = window.getSelection();
        if (s.rangeCount > 0) s.removeAllRanges();
        s.addRange(savedRange);
      } else if (document.createRange) {
        //non IE and no selection
        window.getSelection().addRange(savedRange);
      } else if (document.selection) {
        //IE
        savedRange.select();
      }
    }
  };

  // const s3Config = {
  //   bucketName: "gxsocialprofilecover",
  //   dirName: "postimages",
  //   region: "us-east-2",
  //   accessKeyId: "AKIA4HKGLPLP7H73PB57",
  //   secretAccessKey: "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",
  // };

  // const s3Config = {
  //   bucketName: "connections-app-dev",
  //   dirName: "postimages",
  //   region: "us-east-2",
  //   accessKeyId: "AKIAUEO6Q6T7E5XJOAQR",
  //   secretAccessKey: "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",
  // };

  AWS.config.update({
    accessKeyId: "AKIAUEO6Q6T7E5XJOAQR",
    secretAccessKey: "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",
    //   accessKeyId: "AKIAUEO6Q6T7BHZWWS6F",
    //   secretAccessKey: "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",
  });

  const myBucket = new AWS.S3({
    params: { Bucket: "connections-app-dev" },
    region: "ap-south-1",
  });

  useEffect(() => {
    let newArr = [];
    item.liked_users.map((item) => {
      newArr.push(item.id);
    });
    setLikedUsers(newArr);
  }, [item]);

  const toggle = (id) => {
    // setSelectedPost(id);
    if (showCommentArr.find((o) => o === id)) {
      var index = showCommentArr.indexOf(id);
      const newArr = showCommentArr.filter((_, i) => i !== index);
      setShowCommentArr(newArr);
    } else {
      getComment({
        variables: {
          email: localStorage.getItem("user_account"),
          id: item.id,
        },
      });
      setShowCommentArr([...showCommentArr, id]);
    }
  };
  // console.log(postComments);
  //

  const showReadMoreButton = (item) => {
    if (item.is_this_non_course_video_post === true) {
      if (postBodyRef.current) {
        if (postBodyRef.current.scrollHeight >= 200) {
          return "block";
        } else {
          return "none";
        }
      }
    } else {
      if (postBodyRef.current) {
        // console.log("conditionchk", postBodyRef.current.scrollHeight, 390);
        if (postBodyRef.current.scrollHeight >= 200) {
          return "block";
        } else {
          return "none";
        }
      }
    }
  };

  useEffect(() => {
    if (likeDislikePostData.data) {
      setLikedUsers(likeDislikePostData.data.like_dislike_for_post);
      console.log(
        "likedusrers",
        likeDislikePostData.data.like_dislike_for_post
      );
    }
    // console.log(likeDislikePostData.data.like_dislike_for_post);
  }, [likeDislikePostData.data]);

  const handleLike = (item) => {
    if (likedUsers.length > 0) {
      if (likedUsers.indexOf(localStorage.getItem("pulse_user_id")) > -1) {
        likeDislikePost({
          variables: {
            user_id: localStorage.getItem("pulse_user_id"),
            id: item.id,
            email: localStorage.getItem("user_account"),
            dislikeflag: true,
          },
        });
      } else {
        likeDislikePost({
          variables: {
            user_id: localStorage.getItem("pulse_user_id"),
            id: item.id,
            email: localStorage.getItem("user_account"),
            dislikeflag: false,
          },
        });
      }
    } else {
      likeDislikePost({
        variables: {
          user_id: localStorage.getItem("pulse_user_id"),
          id: item.id,
          email: localStorage.getItem("user_account"),
          dislikeflag: false,
        },
      });
    }

    // if (
    //   item.liked_users.find(o => o.id !== "2c506f5e2c4e5f30627fae7dc8b411bd") ||
    //   item.liked_users.find(
    //     o => o.id !== "2c506f5e2c4e5f30627fae7dc8b411bd"
    //   ) === undefined
    // ) {
    //   console.log("liked_old", item.liked_users);
    //
    // }
    // setLikeCount(Number(item.liked_users.length) + 1);
  };

  const showComments = (element) => {
    return (
      <Card>
        {getCommentData.data ? (
          getCommentData.data.get_comments_of_a_post.map((item, index) => {
            return (
              <>
                <div style={{ display: "flex" }} key={index}>
                  <div>
                    {item.author.profile_image !== null ? (
                      <Avatar
                        // style={{ backgroundColor: "#87d068" }}
                        // icon="user"
                        src={item.author.profile_image}
                        size={30}
                      />
                    ) : (
                      <Avatar src={prof} size={30} />
                    )}
                  </div>
                  &nbsp;
                  <div>
                    <p
                      style={{
                        backgroundColor: "#F5F5F5",
                        padding: "5px",
                        borderRadius: "8px",
                      }}
                    >
                      <span style={{ color: "#40A9FF", fontWeight: "bold" }}>
                        {item.author.name}
                      </span>
                      : {item.text}
                    </p>
                    {/* <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "-10px",
                      }}
                    >
                      <div onClick={(e) => increaseCommentCount(item)}>
                        Like
                      </div>
                      <div>
                        {item.liked_users.length > 0 ? (
                          <Tag
                            color="geekblue"
                            onClick={(e) => showCommentedUsers(item)}
                          >
                            <LikeOutlined />
                            &nbsp; {item.liked_users.length}
                          </Tag>
                        ) : (
                          ""
                        )}
                      </div>
                    </p> */}
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <LoadingOutlined />
          </div>
        )}

        <div style={{ display: "flex" }}>
          {/* <div>
            <Avatar
              style={{ backgroundColor: "#87d068" }}
              icon="user"
              size={30}
            />
          </div>
          &nbsp; */}
          <div style={{ width: "100%" }}>
            <Input
              value={commentInput}
              onChange={(e) => setCommentInput(e.target.value)}
              type="text"
              placeholder="write a comment..."
              style={{ borderRadius: "100px" }}
            />
          </div>
          &nbsp;
          <div>
            <Button
              shape="circle"
              onClick={(e) => {
                commentOnPost({
                  variables: {
                    user_id: localStorage.getItem("pulse_user_id"),
                    id: item.id,
                    email: localStorage.getItem("user_account"),
                    text: commentInput,
                  },
                  refetchQueries: [
                    {
                      query: GET_COMMENT,
                      variables: {
                        email: localStorage.getItem("user_account"),
                        id: item.id,
                      },
                    },
                  ],
                  awaitRefetchQueries: true,
                });
                setCommentInput("");
              }}
            >
              {!commentOnPostData.loading ? (
                <PlusOutlined />
              ) : (
                <LoadingOutlined />
              )}
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  useEffect(() => {
    // console.log(savePostData, "qwvdjhwqevdjwq");
    if (unsavePostData.data) {
      console.log(savePostData);
      message.destroy();
      message.success("Post Removed from Saved Posts");
    }
  }, [unsavePostData.data]);

  useEffect(() => {
    // console.log(savePostData, "qwvdjhwqevdjwq");
    if (savePostData.data) {
      console.log(savePostData);
      message.destroy();
      message.success("Post saved successfully");
    }
  }, [savePostData.data]);

  const handleSavePost = (item) => {
    message.loading("Saving the post");
    savePost({
      variables: {
        user_id: localStorage.getItem("pulse_user_id"),
        postId: item.id,
      },
      refetchQueries: [
        {
          query: MY_SAVED_POSTS,
          variables: {
            user_id: localStorage.getItem("pulse_user_id"),
            // email: localStorage.getItem("user_account"),
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const handleUnsavePost = (item) => {
    message.loading("Removing the post from saved list");
    unsavePost({
      variables: {
        user_id: localStorage.getItem("pulse_user_id"),
        postId: item.id,
      },
      refetchQueries: [
        {
          query: MY_SAVED_POSTS,
          variables: {
            user_id: localStorage.getItem("pulse_user_id"),
            // email: localStorage.getItem("user_account"),
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const handleDeletePost = (item) => {
    message.loading("Deleting The Post", 0);
    deletePost({
      variables: {
        user_id: localStorage.getItem("pulse_user_id"),
        email: localStorage.getItem("user_account"),
        postId: item.id,
      },
      refetchQueries: [
        {
          query: MY_POSTS,
          variables: {
            user_id: localStorage.getItem("pulse_user_id"),
            // email: localStorage.getItem("user_account"),
          },
        },
        {
          query: NEWS_FEED,
          variables: {
            user_id: localStorage.getItem("pulse_user_id"),
            // email: localStorage.getItem("user_account"),
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const handleEditPost = (item) => {
    setVisibleEditPost(true);
    setSelectedPost(item);
    setEditedTitle(item.title);
    setEditedBody(item.body);
  };

  const getEditedImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      insertMyEditedImage(event.target.files[0]);
    }
    // insertMyImage(event.target.files[0]);
  };

  const insertMyEditedImage = async (file) => {
    setEditLoading(true);

    let fileExtention = file.name.split(".")[1];
    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: `test/${nanoid()}.${fileExtention}`,
      ContentType: `image/${fileExtention}`,
    };

    myBucket.upload(params, function (err, data) {
      if (!err) {
        //   console.log(err, data, "hdcjhwcjhwv");
        // console.log(`https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`);
        message.success("File Upload Success", 2);
        let url = `https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`;
        // editBodyRef.current.focus();
        restoreSelection();

        document.execCommand("insertImage", false, url);
        //
        // insertToEditor(url);
        setEditLoading(false);
      } else {
        console.log(err);
        setEditLoading(false);
        message.destroy();
        message.error("File Upload Failed", 2);
      }
    });

    // setEditLoading(true);
    // const S3Client = new S3(s3Config);
    // // let uploaded = await S3Client.uploadFile(selectedImg);
    // let uploaded_edited_data;
    // try {
    //   uploaded_edited_data = await S3Client.uploadFile(
    //     selectedEditedImg,
    //     selectedEditedImg.name
    //   );
    //   message.success("File Upload Success", 2);

    //   let url =
    //     "https://djoplec97gdag.cloudfront.net" +
    //     uploaded_edited_data.location.substring(
    //       55,
    //       uploaded_edited_data.location.length
    //     );
    //   // insertToEditor(uploaded_data.location);
    //   // console.log(bodyRef.current.getSelection());
    //   editBodyRef.current.focus();
    //   // moveCaret(window, 20);
    //   restoreSelection();
    //   document.execCommand("insertImage", false, url);
    //   setEditLoading(false);
    //   // uploaded = null;
    // } catch (e) {
    //   console.log(e);
    //   setEditLoading(false);
    //   message.destroy();
    //   message.error("File Upload Failed", 2);
    // }
  };

  return (
    <>
      <Row
        style={{ padding: "10px 0px" }}
        onClick={(e) =>
          console.log(
            postBodyRef.current ? postBodyRef.current.scrollHeight : "lol"
          )
        }
      >
        <Col span={24}>
          <Card
            onClick={(e) => {
              console.log(item);
              localStorage.setItem("selected_post", JSON.stringify(item));
            }}
            bodyStyle={{
              padding: "0px",
            }}
            style={{ borderRadius: "8px" }}
            actions={
              item.locked !== true
                ? [
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color:
                          likedUsers.indexOf(
                            localStorage.getItem("pulse_user_id")
                          ) > -1
                            ? "blue"
                            : "",
                      }}
                      onClick={(e) => {
                        if (tokenValid) {
                          handleLike(item);
                        }
                      }}
                    >
                      {!likeDislikePostData.loading ? (
                        <LikeOutlined key="like" />
                      ) : (
                        <LoadingOutlined key="like" />
                      )}
                      &nbsp;{" "}
                      <span>Like ({likedUsers ? likedUsers.length : 0})</span>
                    </span>,
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={(e) => {
                        toggle(item.id);
                      }}
                    >
                      <EditOutlined key="edit" />
                      &nbsp;
                      <span>
                        Comment (
                        {getCommentData.data
                          ? getCommentData.data.get_comments_of_a_post.length
                          : item.comment_count}
                        )
                      </span>
                    </span>,
                  ]
                : null
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "15px",
                justifyContent: "space-between",
                padding: "10px 20px",
              }}
            >
              {/* <Popover
                // trigger={"click"}
                className="myPopover"
                bodyStyle={{ padding: "0px" }}
                // visible={item.author.email !== "chiranjib@gmail.com"}
                content={
                  <div style={{ width: "300px" }}>
                    <ProfileCard email={item.author.email} />
                  </div>
                }
              > */}
              <Link to={`/${item.author ? item.author.username : ""}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {item.author ? (
                    item.author.profile_image ? (
                      <Avatar src={item.author.profile_image} size={40} />
                    ) : (
                      <Avatar src={prof} size={40} />
                    )
                  ) : (
                    <Avatar
                      src={userData ? userData.profile_image : prof}
                      size={40}
                    />
                  )}
                  &nbsp;&nbsp;
                  <Link to={`/${item.author ? item.author.username : ""}`}>
                    <div
                      onClick={(e) => {
                        if (tokenValid) {
                          localStorage.setItem(
                            "user_profile_email",
                            item.author.email
                          );
                          localStorage.setItem(
                            "selected_user_id",
                            item.author.id
                          );
                        }
                      }}
                    >
                      <h4 style={{ margin: "0px", cursor: "pointer" }}>
                        {item.author ? item.author.name : userData.name}
                      </h4>
                      <small>
                        {new Date(Number(item.timestamp)).toDateString()}
                      </small>
                    </div>
                  </Link>
                </div>
              </Link>
              {/* </Popover> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tag>{item.post_tier ? item.post_tier[0].name : ""}</Tag>
                {window.location.href.split("/").pop() !== "saved" ? (
                  item.author ? (
                    item.author.email ===
                    localStorage.getItem("user_account") ? (
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="0"
                              onClick={(e) => handleEditPost(item)}
                            >
                              <EditOutlined />
                              &nbsp;Edit Post
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                              key="1"
                              onClick={(e) => handleDeletePost(item)}
                            >
                              <DeleteOutlined />
                              &nbsp; Delete Post
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                              key="2"
                              onClick={(e) => handleSavePost(item)}
                            >
                              <SaveOutlined />
                              &nbsp; Save Post
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <SettingOutlined style={{ fontSize: "15px" }} />
                      </Dropdown>
                    ) : (
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="0"
                              onClick={(e) => handleSavePost(item)}
                            >
                              <SaveOutlined />
                              &nbsp; Save Post
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <SettingOutlined style={{ fontSize: "15px" }} />
                      </Dropdown>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="0"
                          onClick={(e) => handleUnsavePost(item)}
                        >
                          <SaveOutlined />
                          &nbsp; Remove Saved Post
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <SettingOutlined style={{ fontSize: "15px" }} />
                  </Dropdown>
                )}
                {/* {item.author ? (
                  item.author.email ? (
                    item.author.email === localStorage.getItem("user_account")
                  ) : "" ? (
                   
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )} */}
              </div>
            </div>
            <Divider dashed style={{ margin: "0px" }} />

            {/* {postBodyRef.current.offsetHeight > 450 ? ( */}
            <div style={{ padding: item.body ? "10px 20px" : "10px 0px" }}>
              {!item.locked ? (
                item.is_this_non_course_video_post === true ? (
                  <Link
                    to={
                      item.video_id ? `/videos/${item.id}` : `/posts/${item.id}`
                    }
                  >
                    <h3
                      style={{
                        padding: item.body ? "10px 0px" : "10px 20px",
                        display: "flex",
                        alignItems: "center",
                        color: "#1565C0",
                      }}
                    >
                      <PlayCircleOutlined />
                      &nbsp; {item.title}
                    </h3>
                  </Link>
                ) : (
                  <Link
                    to={
                      item.video_id ? `/videos/${item.id}` : `/posts/${item.id}`
                    }
                  >
                    <h3
                      style={{
                        padding: item.body ? "10px 0px" : "10px 20px",
                        color: "#1565C0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Icon type="file" />
                      &nbsp; */}
                      {item.title}
                    </h3>
                  </Link>
                )
              ) : (
                <h5 style={{ padding: item.body ? "10px 0px" : "10px 20px" }}>
                  {item.title}
                </h5>
              )}

              <div
                id="bodyDiv"
                className="postbody"
                // style={{
                //   paddingTop: "10px"
                // }}
              >
                {!item.locked ? (
                  <>
                    <div
                      className="postBody"
                      ref={postBodyRef}
                      style={{ maxHeight: "200px" }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: JSON.parse(item.body),
                        }}
                      />
                      {/* {ReactHtmlParser(JSON.parse(item.body))} */}
                    </div>
                    {!item.locked ? (
                      <div
                        // className="readMoreDiv"
                        style={{
                          display: postBodyRef.current
                            ? postBodyRef.current.scrollHeight >= 200
                              ? "block"
                              : "none"
                            : "",
                          padding: "10px 0px",
                        }}
                      >
                        <Link
                          to={
                            item.video_id
                              ? `/videos/${item.id}`
                              : `/posts/${item.id}`
                          }
                        >
                          Read More ...
                          {/* <Button
                            style={{ marginTop: "10px" }}
                            type="primary"
                            shape="round"
                            icon="read"
                            size="large"
                          >
                            Read More
                          </Button> */}
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    {item ? (
                      item.is_this_non_course_video_post === true ? (
                        <Link
                          to={`/videos/${item.id}`}
                          style={{ color: "#000000a6" }}
                        >
                          <Card
                            bodyStyle={{ padding: "10px" }}
                            className="shadow"
                            style={{
                              borderRadius: "8px",
                              marginTop: "10px",
                              borderColor: "#F5F5F5",
                            }}
                          >
                            <div style={{ display: "flex", padding: "10px" }}>
                              <div>
                                <img
                                  src={item.thumbnail}
                                  alt=""
                                  style={{ width: "200px" }}
                                />
                              </div>
                              <div style={{ padding: "0px 10px" }}>
                                <div>
                                  <h4>{item.video_title}</h4>
                                </div>

                                <p
                                  style={{
                                    color: "darkgray",
                                    marginTop: "10px",
                                  }}
                                >
                                  {item.video_description.substring(0, 200)}
                                </p>
                              </div>
                            </div>
                          </Card>
                        </Link>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${lockedImg})`,
                      backgroundSize: "cover",
                      textAlign: "center",
                      padding: "40px 0px",
                    }}
                  >
                    <LockFilled style={{ fontSize: "40px", color: "white" }} />

                    <h4 style={{ color: "white" }}>This is a Premium Post</h4>
                    <h6>
                      Please Subscribe to{" "}
                      {tokenValid
                        ? item.author
                          ? item.author.username
                          : userData.name
                        : ""}{" "}
                      to see this post
                    </h6>
                    <Link
                      to={`/${
                        tokenValid
                          ? item.author
                            ? item.author.username
                            : userData.username
                          : ""
                      }`}
                    >
                      <Button
                        onClick={(e) => {
                          localStorage.setItem(
                            "user_profile_email",
                            item.author.email
                          );
                        }}
                        style={{
                          borderRadius: "20px",
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                      >
                        Subscribe
                      </Button>
                    </Link>
                  </div>
                )}
              </div>

              <div
                style={{
                  marginTop: "10px",
                  padding: item.body ? "0px" : "10px 20px",
                  cursor: "pointer",
                }}
              >
                {item.trends
                  ? item.trends.map((item1, index1) => (
                      <Tag
                        key={index1}
                        // style={{ cursor: "pointer" }}
                        color="blue"
                        onClick={() => {
                          setTrendClicked(true);

                          setSelectedTrend({
                            name: item1.name,
                            count: item1.post_count,
                          });
                          // setTrendPostCount(item1.post_count);
                          getPostsOfTrend({
                            variables: {
                              id: item1.id,
                              user_id: localStorage.getItem("pulse_user_id"),
                            },
                          });
                          window.scrollTo(0, 0);
                        }}
                      >
                        #{item1.name}
                      </Tag>
                    ))
                  : ""}
              </div>
            </div>
          </Card>

          {showCommentArr.find((o) => o === item.id) ? showComments(item) : ""}
        </Col>
      </Row>

      {/* Edit Post Modal */}

      <Drawer
        width="50vw"
        // title="Basic Modal"
        footer={null}
        visible={visibleEditPost}
        onClose={(e) => setVisibleEditPost(false)}
      >
        {editedTitle ? (
          <>
            <Input
              size="large"
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              style={{
                fontSize: "25px",
                border: "none",
                fontWeight: "bold",
              }}
            />
            <div
              onMouseUp={saveSelection}
              style={{ padding: "10px" }}
              className="editableDivStyle postbody"
              ref={editBodyRef}
              contenteditable="true"
              dangerouslySetInnerHTML={{
                __html: JSON.parse(editedBody),
              }}
            />
          </>
        ) : (
          ""
        )}
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "left",
          }}
        >
          <Button
            style={{ marginRight: 8 }}
            onClick={(e) => setVisibleEditPost(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              updatePost({
                variables: {
                  user_id: localStorage.getItem("pulse_user_id"),
                  postId: selectedPost.id,
                  email: localStorage.getItem("user_account"),
                  title: editedTitle,
                  body: editBodyRef.current.innerHTML
                    ? JSON.stringify(editBodyRef.current.innerHTML)
                    : null,
                },
              });
              setVisibleEditPost(false);
              message.success("Post Updated Successfully");
            }}
          >
            Submit
          </Button>
          <Divider type="vertical" />
          <Button
            style={{ borderStyle: "none" }}
            onClick={(e) => document.execCommand("bold", false, null)}
          >
            <BoldOutlined style={{ fontSize: "15px", fontWeight: "bold" }} />
          </Button>
          <Button
            style={{ borderStyle: "none" }}
            onClick={(e) => document.execCommand("italic", false, null)}
          >
            <ItalicOutlined style={{ fontSize: "15px", fontWeight: "bold" }} />
          </Button>
          <Button
            style={{ borderStyle: "none" }}
            onClick={(e) => document.execCommand("underline", false, null)}
          >
            <UnderlineOutlined
              style={{ fontSize: "15px", fontWeight: "bold" }}
            />
          </Button>
          &nbsp;{" "}
          <label for="editimage">
            <input
              accept="image/*"
              onChange={getEditedImage}
              type="file"
              name="editimage"
              id="editimage"
              style={{ display: "none" }}
            />
            {!editLoading ? (
              <CameraOutlined
                style={{ fontSize: "15px", fontWeight: "bold" }}
              />
            ) : (
              <>
                <LoadingOutlined
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                />{" "}
                Uploading
              </>
            )}
          </label>
        </div>
      </Drawer>
    </>
  );
};

export default PostComponent;
