import React, { useState, useEffect, useContext } from "react";
import { parse, stringify } from "flatted";
import { useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import { MyContext } from "../Context";
import bgImg from "../img/cover.jpg";
import prof from "../img/user.svg";

import {
  Row,
  Col,
  Card,
  Avatar,
  Icon,
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Spin,
  Badge,
  Tag,
} from "antd";

const USER_INFO = gql`
  query getUser($email: ID!) {
    get_user(data: { email: $email }) {
      id
      name
      email
      bio
      date_of_birth
      profile_image
      cover_image
      following_count
      my_follower_count
      user_type_id
      city
    }
  }
`;

const ProfileCard = ({ email }) => {
  const value = useContext(MyContext);

  const userInfo = useQuery(USER_INFO, {
    variables: {
      email: email,
    },
  });

  return (
    <>
      <div style={{ borderRadius: "8px" }}>
        <img
          src={
            userInfo.data
              ? userInfo.data.get_user.cover_image !== null
                ? userInfo.data.get_user.cover_image
                : bgImg
              : bgImg
          }
          alt=""
          height="100px"
          width="100%"
          style={{ borderRadius: "8px 8px 0px 0px" }}
        />
      </div>
      <div
        style={{
          marginTop: "-25px",
          padding: "0px 20px",
        }}
      >
        <Link
          to={`/${userInfo.data ? userInfo.data.get_user.name : ""}`}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {userInfo.data ? (
            userInfo.data.get_user.profile_image !== null ? (
              <Avatar
                onClick={(e) => localStorage.setItem("user_account", email)}
                className="shadow-down"
                src={userInfo.data.get_user.profile_image}
                style={{
                  cursor: "pointer",
                  borderStyle: "solid",
                  borderColor: "white",
                  borderWidth: "2px",
                  backgroundColor: "white",
                  // marginLeft: "17px"
                }}
                icon="camera"
                size={50}
              />
            ) : (
              <Avatar
                className="shadow-down"
                // style={{ backgroundColor: "#87d068" }}
                src={prof}
                size={50}
              />
            )
          ) : (
            ""
          )}

          <Button type="primary" size="small" style={{ marginTop: "28px" }}>
            Follow
          </Button>
        </Link>
      </div>
      <div style={{ margin: "8px 20px" }}>
        <h4>{userInfo.data ? userInfo.data.get_user.name : ""}</h4>
        <p> {userInfo.data ? userInfo.data.get_user.bio : ""}</p>
      </div>
    </>
  );
};

export default ProfileCard;
