import React from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";

import "antd/dist/antd.css";
import "./css/style.scss";
import "./App.css";

import Middleware from "./pages/Middleware";
import Login from "./pages/Login";

import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";

const client = new ApolloClient({
  uri: "https://connectionsapi.globalxchange.io/graphql",
  // uri: "http://localhost:3997/graphql",
  request: (operation) => {
    console.log(
      "mydata",
      localStorage.getItem("token"),
      localStorage.getItem("user_account")
    );
    // const token = localStorage.getItem("access_token");
    operation.setContext({
      headers: {
        token: localStorage.getItem("token")
          ? localStorage.getItem("token")
          : "",
        email: localStorage.getItem("user_account"),
      },
    });
  },
});

function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <BrowserRouter basename="/">
          <Middleware />
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
}

export default App;
