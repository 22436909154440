import {
  Avatar,
  Button,
  Col,
  Divider,
  Tabs,
  message,
  Input,
  Spin,
  Skeleton,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import Axios from "axios";
import {
  Link,
  useNavigate,
  useLocation,
  Switch,
  Route,
  Outlet,
} from "react-router-dom";
import Streams from "../components/profileSections/Streams";
import Creator from "../components/profileTabs/Creator";
import Collector from "../components/profileTabs/Collector";

import AWS from "aws-sdk";
import CryptoJS from "crypto-js";

import { customAlphabet } from "nanoid";
import { MyContext } from "../Context";
import { CameraOutlined } from "@material-ui/icons";

const USER_INFO = gql`
  query getUser($user_id: ID) {
    get_user(data: { user_id: $user_id }) {
      id
      name
      username
      email
      bio
      date_of_birth
      profile_image
      cover_image
      following_count
      my_follower_count
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
      long_bio
      user_type_id
      city
      achievements {
        id
        title
        description
        endorsed_users
      }
    }
  }
`;

const Profile = () => {
  const {
    editable,
    // userInfo,
    coverImage,
    setCoverImage,
    coverImageS3url,
    setCoverImageS3url,
    loading,
    setLoading,
    uname,
    setUname,
    profileUsername,
    setProfileUsername,
    ubio,
    setUbio,
    profileImage,
    setProfileImage,
    profileImageS3url,
    setProfileImageS3url,
    prof,
    udob,
    setUdob,
    userEmail,
    setUserEmail,
    selectedUserId,
    setSelectedUserId,
    userCity,
    setUserCity,
    creatorView,
    setCreatorView,
    tokenValid,
  } = useContext(MyContext);
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedProfileMenu, setSelectedProfileMenu] = useState("posts");
  const [selectedProfileTab, setSelectedProfileTab] = useState("Creator");

  const [sidebarTabName, setSidebarTabName] = useState("");

  const [readMore, setReadMore] = useState(false);

  const [foundUname, setFoundUname] = useState("");

  const nanoid = customAlphabet("23456789abcdefghijklmnopqrstuvwxyz", 15);
  const S3_BUCKET = "connections-app-dev";

  // useEffect(() => {
  //   if (userInfo.data) {
  //     console.log(userInfo.data.get_user.bio.substring(0, 150), "kjbewkjwebfk");
  //   }
  // }, [userInfo]);

  useEffect(() => {
    // if (selectedProfileTab === "Creator") {
    //   history.push(`${localStorage.getItem("actual_username")}/creator`);
    // } else if (selectedProfileTab === "Collector") {
    //   history.push(`/collector`);
    // }

    setSelectedProfileMenu(location.pathname);
  }, []);

  useEffect(() => {
    if (!tokenValid) {
      localStorage.clear();
      navigate("login");
    }
  }, []);

  useEffect(() => {
    if (window.location.href.split("/").pop() === "posts") {
      setSelectedProfileMenu("posts");
    } else if (window.location.href.split("/").pop() === "streams") {
      setSelectedProfileMenu("streams");
    } else if (window.location.href.split("/").pop() === "gallery") {
      setSelectedProfileMenu("gallery");
    } else if (window.location.href.split("/").pop() === "memories") {
      setSelectedProfileMenu("memories");
    } else if (window.location.href.split("/").pop() === "discussions") {
      setSelectedProfileMenu("discussions");
    } else if (window.location.href.split("/").pop() === "interactions") {
      setSelectedProfileMenu("interactions");
    } else {
      setSelectedProfileMenu("posts");
    }
  }, [window.location.href.split("/").pop()]);

  // useEffect(() => {
  //   Axios.post(
  //     `https://pulseapi.globalxchange.io/get_data`,
  //     {
  //       username: window.location.href.split("/").pop(),
  //     },
  //     {
  //       headers: {
  //         token: localStorage.getItem("token"),
  //         email: localStorage.getItem("user_account"),
  //       },
  //     }
  //   ).then((res) => {
  //     console.log("hdjwhedwemail", res.data);
  //     // var bytes = CryptoJS.Rabbit.decrypt(res.data, "apple");

  //     // var originalText = bytes.toString(CryptoJS.enc.Utf8);

  //     setUserEmail(res.data);
  //   });
  // }, [window.location.href.split("/").pop()]);

  var userInfo = useQuery(USER_INFO, {
    variables: {
      user_id: localStorage.getItem("selected_user_id"),
    },
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "connections-app-dev" },
    region: "ap-south-1",
  });

  useEffect(() => {
    localStorage.setItem("selected_user_id", "");
    // console.log(
    //   "popped",
    //   window.location.href.split("/").pop(),
    //   window.location.href.match(new RegExp("/", "g")).length
    // );

    let urlvar = window.location.href.match(new RegExp("/", "g")).length;

    // const afterdomain = window.location.href.split("/")[4];
    // setFoundUname(afterdomain);
    // if (afterdomain.includes("/")) {
    //   setFoundUname(afterdomain.location.href.split("/")[0]);
    // } else {
    //   setFoundUname(afterdomain);
    // }
    // console.log(afterdomain, "pokjwkjc");

    Axios.post(
      `https://connectionsapi.globalxchange.io/get_user_data_direct`,
      {
        username: window.location.pathname.replace(/^\/([^\/]*).*$/, "$1"),
      },
      {
        headers: {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("user_account"),
        },
      }
    ).then((res) => {
      // Axios.post(`https://comms.globalxchange.com/coin/verifyToken`, {
      //   email: localStorage.getItem("user_account"),
      //   token: localStorage.getItem("token"),
      // }).then(res => {
      //   if(res.data.status){

      //   }else{
      //       localStorage.clear();
      // navigate("login");
      //   }
      // })

      console.log("hdjwhedwemail", res.data);
      var bytes = CryptoJS.Rabbit.decrypt(res.data, "g84898432hfiandldcoiee");

      var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      if (originalText.message) {
        if (originalText.message !== "success") {
          localStorage.clear();
          navigate("login");
          // alert("User Not found");
        } else {
          console.log(originalText, "originalText");
          localStorage.setItem("selected_user_id", originalText.id);
          localStorage.setItem("selected_user_email", originalText.email);
          setUserEmail(originalText.email);
          setSelectedUserId(originalText.id);
        }
      } else {
        localStorage.clear();
        navigate("login");
      }
    });
  }, [window.location.href.split("/").pop()]);

  const onCoverImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setCoverImage(e.target.result);
        // this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      uploadCoverImage(event.target.files[0]);
    }
  };

  const onProfileImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      // console.log(event.target.files[0]);
      // setSelectedProfileImg();

      let reader1 = new FileReader();
      reader1.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader1.readAsDataURL(event.target.files[0]);
      uploadProfileImage(event.target.files[0]);
    }
  };

  const uploadCoverImage = async (file) => {
    setLoading(true);

    let fileExtention = file.name.split(".")[1];
    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: `test/${nanoid()}.${fileExtention}`,
      ContentType: `image/${fileExtention}`,
    };

    myBucket.upload(params, function (err, data) {
      if (!err) {
        //   console.log(err, data, "hdcjhwcjhwv");
        // console.log(`https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`);
        message.success("File Upload Success", 2);
        let url = `https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`;

        setCoverImageS3url(url);

        // insertToEditor(url);
        setLoading(false);
      } else {
        console.log(err);
        setLoading(false);
        message.destroy();
        message.error("File Upload Failed", 2);
      }
    });
  };

  const uploadProfileImage = async (file) => {
    setLoading(true);

    let fileExtention = file.name.split(".")[1];
    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: `test/${nanoid()}.${fileExtention}`,
      ContentType: `image/${fileExtention}`,
    };

    myBucket.upload(params, function (err, data) {
      if (!err) {
        //   console.log(err, data, "hdcjhwcjhwv");
        // console.log(`https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`);
        message.success("File Upload Success", 2);
        let url = `https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`;

        setProfileImageS3url(url);
        // insertToEditor(url);
        setLoading(false);
      } else {
        console.log(err);
        setLoading(false);
        message.destroy();
        message.error("File Upload Failed", 2);
      }
    });
  };

  const conditionalCover = () => {
    if (coverImageS3url) {
      return coverImageS3url;
    } else if (userInfo.data) {
      if (userInfo.data.get_user.cover_image) {
        return userInfo.data.get_user.cover_image;
      } else {
        return coverImage;
      }
    }
  };

  // {readMore && userInfo.data.get_user.bio !== null
  //   ? userInfo.data.get_user.bio
  //   : userInfo.data.get_user.bio !== null
  //   ? userInfo.data
  //     ? userInfo.data.get_user.bio.substring(0, 150)
  //     : ""
  //   : ""}
  // ...&nbsp;
  // <span
  //   onClick={(e) => setReadMore(!readMore)}
  //   style={{ color: "#009EFC", cursor: "pointer" }}
  // >
  //   {userInfo.data
  //     ? userInfo.data.get_user.bio.substring(0, 150)
  //         .length === 150
  //       ? readMore
  //         ? "read less"
  //         : "read more"
  //       : ""
  //     : ""}
  // </span>

  const conditionalBio = () => {
    if (readMore) {
      if (userInfo.data) {
        if (userInfo.data.get_user.bio !== null) {
          return userInfo.data.get_user.bio;
        }
      }
    } else {
      if (userInfo.data) {
        if (userInfo.data.get_user.bio) {
          return <span>{userInfo.data.get_user.bio.substring(0, 150)}</span>;
        }
      }
    }
  };

  const conditionalReadMoreButton = () => {
    if (userInfo.data) {
      if (userInfo.data.get_user.bio !== null) {
        if (userInfo.data.get_user.bio.substring(0, 150).length === 150) {
          if (readMore) {
            return "... read less";
          } else {
            return "... read more";
          }
        } else {
        }
      }
    }
  };

  return (
    <>
      <Col
        xs={24}
        sm={14}
        style={{
          borderRight: "0.5px solid #EDEDED",
          overflowY: "scroll",
          height: "100vh",
        }}
      >
        {!userInfo.loading ? (
          <>
            <div
              style={{
                backgroundImage: `url(${
                  conditionalCover()
                  // selectedUserId === localStorage.getItem("selected_user_id")
                  //   ? editable === true
                  //     ? coverImage
                  //     : userInfo.data
                  //     ? userInfo.data.get_user.cover_image
                  //     : coverImage
                  //   : userInfo.data
                  //   ? userInfo.data.get_user.cover_image !== null
                  //     ? userInfo.data.get_user.cover_image
                  //     : coverImage
                  //   : coverImage
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "223px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: editable ? 0.6 : 1,
              }}
            >
              {editable ? (
                <label for="image">
                  <input
                    accept="image/*"
                    // value={coverImage}
                    // onChange={e =>
                    //   setCoverImage(URL.createObjectURL(e.target.files[0]))
                    // }
                    onChange={onCoverImageChange}
                    type="file"
                    name="image"
                    id="image"
                    style={{ display: "none" }}
                  />
                  <Avatar
                    style={{
                      backgroundColor: "white",
                      zIndex: "0",
                      color: "black",
                      margin: "20px",
                      opacity: 1,
                      cursor: "pointer",
                      marginTop: "-30px",
                      opacity: "0.8",
                    }}
                    icon={<CameraOutlined style={{ fontSize: "40px" }} />}
                    size={118}
                  />
                </label>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                borderRadius: "50%",
                borderColor: "gray",
                marginTop: "-65px",
                paddingLeft: "41px",
              }}
            >
              {userEmail === localStorage.getItem("user_account") ? (
                editable === true ? (
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                    }}
                  >
                    {/* <label for="image1">
                      <input
                        accept="image/*"
                        onChange={onProfileImageChange}
                        type="file"
                        name="image1"
                        id="image1"
                        style={{ display: "none" }}
                      />
                      <div
                        style={{
                          // backgroundImage: `url(${
                          //   userInfo.data
                          //     ? userInfo.data.get_user.profile_image
                          //     : prof
                          // })`,
                          background: "black",
                          backgroundSize: "cover",
                          height: "118px",
                          width: "118px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          opacity: 1,
                          borderRadius: "50%",
                          zIndex: "99999",
                        }}
                      >
                        <CameraOutlined style={{ color: "white" }} />
                      </div>

                     
                    </label> */}
                    <label for="image1">
                      <input
                        accept="image/*"
                        onChange={onProfileImageChange}
                        type="file"
                        name="image1"
                        id="image1"
                        style={{ display: "none" }}
                      />

                      <Avatar
                        src={profileImage}
                        style={{
                          cursor: "pointer",
                        }}
                        icon={<CameraOutlined style={{ fontSize: "40px" }} />}
                        size={118}
                      />
                    </label>
                  </div>
                ) : (
                  <img
                    style={{
                      borderRadius: "50%",
                      width: "118px",
                      height: "118px",
                    }}
                    src={
                      userInfo.data
                        ? userInfo.data.get_user.profile_image
                          ? userInfo.data.get_user.profile_image
                          : prof
                        : prof
                    }
                    alt=""
                  />
                )
              ) : (
                <Avatar
                  src={
                    userInfo.data
                      ? userInfo.data.get_user.profile_image
                        ? userInfo.data.get_user.profile_image
                        : prof
                      : prof
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  size={118}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "41px",
                }}
              >
                <div>
                  <div
                    style={{
                      paddingTop: "26px",
                      fontWeight: "800",
                      color: "#5F6163",
                      fontSize: "24px",
                    }}
                  >
                    {/* {userInfo.data ? userInfo.data.get_user.name : ""} */}
                    {!editable ? (
                      userInfo.data ? (
                        userInfo.data.get_user.name
                      ) : (
                        ""
                      )
                    ) : (
                      <Input
                        value={uname}
                        onChange={(e) => setUname(e.target.value)}
                        style={{
                          marginTop: "10px",
                          border: "dashed",
                          color: "lightgray",
                        }}
                        placeholder={
                          userInfo.data ? userInfo.data.get_user.name : ""
                        }
                      />
                    )}
                  </div>
                  <div className="profile-text ">
                    {!editable ? (
                      userInfo.data ? (
                        `@${userInfo.data.get_user.username}`
                      ) : (
                        ""
                      )
                    ) : (
                      <Input
                        value={profileUsername}
                        onChange={(e) => setProfileUsername(e.target.value)}
                        style={{
                          marginTop: "10px",
                          border: "dashed",
                          color: "lightgray",
                        }}
                        placeholder={
                          userInfo.data
                            ? `@${userInfo.data.get_user.username}`
                            : ""
                        }
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    paddingTop: "26px",
                    fontWeight: "800",
                    color: "#5F6163",
                    fontSize: "24px",
                  }}
                >
                  {userInfo.data
                    ? // `@${userInfo.data.get_user.user_type_id}`
                      userInfo.data.get_user.user_type_id === "a65tgc27u6dr"
                      ? "Regular"
                      : "Influencer"
                    : ""}
                  {/* ) : (
                    <Input
                      value={uname}
                      onChange={(e) => setUname(e.target.value)}
                      style={{
                        marginTop: "10px",
                        border: "dashed",
                        color: "lightgray",
                      }}
                      placeholder={
                        userInfo.data
                          ? `@${userInfo.data.get_user.username}`
                          : ""
                      }
                    />
                  )} */}

                  <div className="profile-text ">Last Seen 1HR Ago</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "41px",
                }}
              >
                <div>
                  <div
                    style={{
                      paddingTop: "26px",
                      fontWeight: "800",
                      color: "#5F6163",
                      fontSize: "15px",
                    }}
                  >
                    Bio
                  </div>
                  <div
                    className="profile-text"
                    style={{ paddingRight: "150px" }}
                  >
                    {!editable ? (
                      userInfo.data ? (
                        <>
                          <span>
                            {conditionalBio()}
                            <span
                              onClick={(e) => setReadMore(!readMore)}
                              style={{ color: "#009EFC", cursor: "pointer" }}
                            >
                              {conditionalReadMoreButton()}
                            </span>
                          </span>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      <TextArea
                        style={{
                          width: "100%",
                          border: "dashed",
                          color: "lightgray",
                        }}
                        width="100%"
                        value={ubio}
                        onChange={(e) => setUbio(e.target.value)}
                        placeholder={
                          userInfo.data
                            ? userInfo.data.get_user.bio
                            : "Add your Bio..."
                        }
                      />
                    )}
                  </div>
                </div>

                {!editable ? (
                  userInfo.data ? (
                    userInfo.data.get_user.city ? (
                      <div style={{ textAlign: "right" }}>
                        <div
                          style={{
                            paddingTop: "26px",
                            fontWeight: "800",
                            color: "#5F6163",
                            fontSize: "15px",
                          }}
                        >
                          City
                        </div>
                        <div className="profile-text">
                          {!editable ? (
                            userInfo.data ? (
                              <>
                                <span>
                                  {userInfo.data.get_user.city
                                    ? userInfo.data.get_user.city
                                    : ""}
                                </span>
                              </>
                            ) : (
                              ""
                            )
                          ) : (
                            <Input
                              value={userCity}
                              onChange={(e) => setUserCity(e.target.value)}
                              style={{
                                marginTop: "10px",
                                border: "dashed",
                                color: "lightgray",
                              }}
                              placeholder={
                                userInfo.data ? userInfo.data.get_user.city : ""
                              }
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                ) : (
                  <div style={{ textAlign: "right" }}>
                    <div
                      style={{
                        paddingTop: "26px",
                        fontWeight: "800",
                        color: "#5F6163",
                        fontSize: "15px",
                      }}
                    >
                      City
                    </div>
                    <div>
                      <Input
                        value={userCity}
                        onChange={(e) => setUserCity(e.target.value)}
                        style={{
                          marginTop: "10px",
                          border: "dashed",
                          color: "lightgray",
                        }}
                        placeholder={
                          userInfo.data
                            ? userInfo.data.get_user.city
                            : "Type your City"
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                borderTop: "0.5px solid #EDEDED",
                borderBottom: "0.5px solid #EDEDED",
                display: window.innerWidth > 900 ? "flex" : "block",
                justifyContent: "space-between",
                padding: "20px 41px",
                marginTop: "46px",
              }}
            >
              <div
                className={
                  selectedProfileMenu === "posts"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                // onClick={(e) => setSelectedProfileMenu("Posts")}
                onClick={(e) => {
                  setSelectedProfileMenu("posts");
                  navigate(`posts`);
                }}
              >
                Posts
              </div>

              <div
                className={
                  selectedProfileMenu === "streams"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("streams");
                  navigate(`streams`);
                }}
              >
                Streams
              </div>

              <div
                className={
                  selectedProfileMenu === "gallery"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("gallery");
                  navigate(`gallery`);
                }}
              >
                Gallery
              </div>
              <div
                className={
                  selectedProfileMenu === "memories"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("memories");
                  navigate(`memories`);
                }}
              >
                Memories
              </div>
              <div
                className={
                  selectedProfileMenu === "discussions"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("discussions");
                  navigate(`discussions`);
                }}
              >
                Discussions
              </div>
              <div
                className={
                  selectedProfileMenu === "interactions"
                    ? "selected-profile-menu"
                    : "unselected-profile-menu hoverstyle1"
                }
                onClick={(e) => {
                  setSelectedProfileMenu("interactions");
                  navigate(`interactions`);
                }}
              >
                Interactions
              </div>
            </div>
            <Outlet />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Skeleton active />
          </div>
        )}

        {/* <div className="links">
          <Link to={`${location.pathname}`} className="link">
            Profile
          </Link>
          <Link to={`${location.pathname}/comments`} className="link">
            Comments
          </Link>
          <Link to={`${location.pathname}/contact`} className="link">
            Contact
          </Link>
        </div>
        <div className="tabs">
          <Switch>
            <Route path={`${location.pathname}`} exact component={Profile} />
            <Route path={`${location.pathname}/comments`} component={Posts} />
            <Route path={`${location.pathname}/contact`} component={Streams} />
          </Switch>
        </div> */}
        {/* <div
          className="profile-text"
          style={{ padding: "20px 41px", marginTop: "46px" }}
        >
          This is {selectedProfileMenu}
        </div> */}
      </Col>
      {window.innerWidth > 900 ? (
        <Col span={6} style={{ padding: "24px 0px" }} className="ant-tab-new">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 37px",
            }}
          >
            <div
              style={{ paddingBottom: "20px" }}
              className={
                selectedProfileTab === "Creator"
                  ? "selected-profile-menu"
                  : "unselected-profile-menu"
              }
              onClick={(e) => {
                setSelectedProfileTab("Creator");
                setCreatorView(null);
                // navigate(`creator`);
              }}
            >
              Creator
            </div>

            <div
              style={{ paddingBottom: "20px" }}
              className={
                selectedProfileTab === "Collector"
                  ? "selected-profile-menu"
                  : "unselected-profile-menu"
              }
              onClick={(e) => {
                setSelectedProfileTab("Collector");
                // navigate(`collector`);
              }}
              // onClick={(e) => setSelectedProfileTab("Collector")}
            >
              Collector
            </div>
          </div>
          <div style={{ padding: "0px 20px" }}>
            {selectedProfileTab === "Creator" ? (
              <div
                style={{
                  display: "flex",
                  height: "2px",
                  width: "100%",
                }}
              >
                <div style={{ width: "35%", background: "#009EFC" }}></div>
                <div style={{ width: "65%", background: "#EDEDED" }}></div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "2px",
                  width: "100%",
                }}
              >
                <div style={{ width: "65%", background: "#EDEDED" }}></div>
                <div style={{ width: "35%", background: "#009EFC" }}></div>
              </div>
            )}
          </div>

          {selectedProfileTab === "Creator" ? <Creator /> : <Collector />}

          {/* <Outlet /> */}
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default Profile;
