import {
  Row,
  Col,
  Input,
  Space,
  Button,
  Menu,
  Dropdown,
  Avatar,
  Popover,
  Divider,
  Tag,
} from "antd";
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  SearchOutlined,
  UserOutlined,
  CaretDownOutlined,
  DownOutlined,
  BellOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Route, useNavigate, Switch, HashRouter, Link } from "react-router-dom";
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import prof from "../img/user.svg";
import { MyContext } from "../Context";
import Axios from "axios";

const USER_INFO = gql`
  query getUser($user_id: ID) {
    get_user(data: { user_id: $user_id }) {
      id
      name
      username
      email
      bio
      date_of_birth
      profile_image
      cover_image
      following_count
      my_follower_count
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
      long_bio
      user_type_id
      city
      achievements {
        id
        title
        description
        endorsed_users
      }
    }
  }
`;

const GET_ALL_USERS = gql`
  query {
    get_all_users {
      id
      email
      name
      username
      profile_image
    }
  }
`;

const ALL_TRENDS = gql`
  {
    trends {
      id
      name
      post_count
    }
  }
`;

const Nav = () => {
  const { tokenValid, setTrendClicked } = useContext(MyContext);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [showDropdown, setShowDropdown] = useState(false);
  const [query, setQuery] = useState("");

  const [count, setCount] = useState(0);
  const handleMenuClick = () => {};
  const navigate = useNavigate();

  const allTrends = useQuery(ALL_TRENDS);
  const getAllUsers = useQuery(GET_ALL_USERS);

  const [getAllUsersApi, setGetAllUsersApi] = useState([]);
  const [allUsername, setAllUsername] = useState([]);
  // const [getAllTrendsApi, setGetAllTrendsApi] = useState([]);

  useEffect(() => {
    Axios.get(`https://connectionsapi.globalxchange.io/get_all_users_api`).then(
      (res) => {
        console.log(res.data, "kjwbfkjwebkfw");
        setGetAllUsersApi([...res.data.names]);
        setAllUsername([...res.data.usernames]);
      }
    );
  }, []);

  var userInfo = useQuery(USER_INFO, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
    },
  });

  let filteredUsers = getAllUsers.data
    ? getAllUsers.data.get_all_users.filter((item) => {
        const lowquery = query.toLowerCase();
        return (item.name + item.username).toLowerCase().indexOf(lowquery) >= 0;
      })
    : "";

  getAllUsersApi.filter((query) => allUsername.includes(query));

  let filteredUsersApi = getAllUsersApi
    ? getAllUsersApi.filter((item) => {
        const lowquery = query.toLowerCase();
        return item.toLowerCase().indexOf(query) >= 0;
      })
    : "";

  // let filteredUsernames = allUsername
  //   ? allUsername.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.toLowerCase().indexOf(query) >= 0;
  //     })
  //   : "";

  let filteredTrends = allTrends.data
    ? allTrends.data.trends.filter((item) => {
        const lowquery = query.toLowerCase();
        return item.name.toLowerCase().indexOf(lowquery) >= 0;
      })
    : "";

  // let filteredTrendsApi = allTrends.data
  //   ? allTrends.data.trends.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.name.toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        console.log("Close logout");
        setShowDropdown(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //   alert("You clicked outside of me!");
          setShowDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  //   useEffect(() => {
  //     console.log("kjwekjfbwkefbw", showDropdown, count);

  //     window.addEventListener("click", () => {
  //       if (count < 2) {
  //         setCount(count + 1);
  //       } else {
  //         setCount(0);
  //       }
  //     });
  //   });

  //   useEffect(() => {
  //     if (showDropdown) {
  //       if (count === 2) {
  //         setShowDropdown(false);
  //         setCount(0);
  //       }
  //     }
  //   }, [count]);

  const conditionalNav = () => {
    if (window.location.href.split("/").pop() !== "login") {
      return (
        <>
          <Row
            style={{
              padding: "15px 20px",
              borderBottom: "0.5px solid #EDEDED",
            }}
          >
            <Col span={4}>
              <Space>
                <div
                  onClick={(e) => {
                    navigate("/home");
                    setTrendClicked(false);
                  }}
                >
                  <img
                    className="hoverstyle1"
                    style={{ cursor: "pointer" }}
                    src={require("../img/logo_short.png")}
                    alt=""
                    // onClick={(e) => history.push("/home")}
                  />
                </div>
                &nbsp;&nbsp;
                {window.innerWidth > 900 ? (
                  <Popover
                    // placement="bottom"
                    placement="bottomLeft"
                    content={
                      <div style={{ width: "500px" }}>
                        {query.length > 0 ? (
                          <>
                            {filteredUsersApi.map((item, index) => {
                              if (index < 14) {
                                return (
                                  <>
                                    <Link
                                      to={`/${
                                        allUsername[
                                          getAllUsersApi.indexOf(item)
                                        ]
                                      }`}
                                      style={{ color: "#000000a6" }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <Link
                                          to={`/${
                                            allUsername[
                                              getAllUsersApi.indexOf(item)
                                            ]
                                          }`}
                                          style={{ color: "#000000a6" }}
                                        >
                                          <div
                                            onClick={(e) => {
                                              localStorage.setItem(
                                                "user_profile_email",
                                                item.email
                                              );
                                            }}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              {item.profile_image ? (
                                                <Avatar
                                                  className="shadow-down"
                                                  src={item.profile_image}
                                                  size={38}
                                                />
                                              ) : (
                                                <Avatar src={prof} size={38} />
                                              )}
                                            </div>
                                            &nbsp;&nbsp;
                                            <div
                                              onClick={(e) => {
                                                localStorage.setItem(
                                                  "user_profile_email",
                                                  item.email
                                                );
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                {item}
                                              </span>
                                              <br />
                                              {/* <span>{item.username}</span> */}
                                            </div>
                                          </div>
                                        </Link>
                                        {/* <Link
                                  to={`/${item.username}`}
                                  style={{ color: "white" }}
                                >
                                  <div
                                    // className="follow-button"
                                    onClick={(e) => {
                                      localStorage.setItem(
                                        "user_profile_email",
                                        item.email
                                      );
                                    }}
                                  >
                                    <ArrowRightOutlined />
                                  </div>
                                </Link> */}
                                      </div>
                                    </Link>
                                    {/* {filteredTrends.length > 0 ? (
                              <Divider
                                dashed
                                style={{ margin: "8px 0px" }}
                              />
                            ) : (
                              ""
                            )} */}
                                  </>
                                );
                              }
                            })}
                            {/* <div style={{ padding: "10px" }}>
                      {filteredTrends.map((item, index) => {
                        return (
                          <Tag
                            // onClick={() => {
                            //   value.setTrendClicked(true);
                            //   value.setSelectedTrend({
                            //     name: item.name,
                            //     count: item.post_count
                            //   });
                            // }}
                            style={{ margin: "5px" }}
                            color="geekblue"
                          >
                            #{item.name}
                          </Tag>
                        );
                      })}
                    </div> */}
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100px",
                            }}
                          >
                            Please type something ...
                          </div>
                        )}
                      </div>
                    }
                    trigger="focus"
                  >
                    {getAllUsersApi ? (
                      getAllUsersApi.length > 0 ? (
                        <Input
                          type="text"
                          value={query}
                          className="nav-search"
                          placeholder="Search Users or Trends"
                          suffix={<SearchOutlined />}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Popover>
                ) : (
                  ""
                )}
              </Space>
            </Col>
            <Col span={12}></Col>
            {tokenValid ? (
              <Col span={8} style={{ textAlign: "right" }}>
                <Space align="center" size="middle" className="spaceitem">
                  <Button
                    className="nav-button1"
                    onClick={(e) => navigate("/home")}
                  >
                    <span className="button1text">Create</span>
                  </Button>
                  <Button className="nav-button2">
                    <span className="button2text">Refer</span>
                  </Button>

                  <div
                    ref={wrapperRef}
                    className="nav-userbutton"
                    onClick={(e) => setShowDropdown(!showDropdown)}
                    style={{
                      zIndex: 999,
                      border: showDropdown ? "none" : "",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          borderRadius: "50%",

                          width: "35px",
                          height: "35px",
                        }}
                        src={
                          userInfo.data
                            ? userInfo.data.get_user.profile_image
                              ? userInfo.data.get_user.profile_image
                              : prof
                            : prof
                        }
                        alt=""
                      />
                      &nbsp;&nbsp;
                      <div className="nav-profile-button">
                        {userInfo.data
                          ? userInfo.data.get_user.name.length > 10
                            ? `${userInfo.data.get_user.name.substring(
                                0,
                                10
                              )}..`
                            : userInfo.data.get_user.name
                          : ""}
                      </div>
                    </div>

                    <div onClick={(e) => e.preventDefault()}>
                      <CaretDownOutlined style={{ color: "#999999" }} />
                    </div>
                  </div>

                  {/* <BellOutlined style={{ fontSize: "20px", color: "#999999" }} /> */}
                </Space>
              </Col>
            ) : (
              <Col span={8} style={{ textAlign: "right" }}>
                <Space align="center" size="middle" className="spaceitem">
                  <Link to="/login">
                    <Button className="nav-button1">
                      <span className="button1text">Login / Register</span>
                    </Button>
                  </Link>
                  {/* <BellOutlined style={{ fontSize: "20px", color: "#999999" }} /> */}
                </Space>
              </Col>
            )}
          </Row>
          {showDropdown ? (
            <Row
              style={{
                padding: "0px 20px",
                position: "absolute",
                right: "142px",
                top: "14px",
                zIndex: 1,
              }}
            >
              <Col span={16} style={{ textAlign: "right" }}></Col>
              <Col span={8} style={{ textAlign: "right" }}>
                {tokenValid ? (
                  <Space align="center" size="middle">
                    <Button className="nav-button1" style={{ display: "none" }}>
                      <span className="button1text">Create</span>
                    </Button>
                    <Button className="nav-button2" style={{ display: "none" }}>
                      <span className="button2text">Refer</span>
                    </Button>
                    <div
                      ref={wrapperRef}
                      className="nav-userbutton-expand"
                      onClick={(e) => setShowDropdown(!showDropdown)}
                    >
                      <div
                        onClick={(e) => {
                          localStorage.clear();
                          window.location.reload();
                        }}
                        className="logoutText"
                        style={{
                          background: "#009EFC",
                          width: "100%",
                          borderRadius: "0px 0px 25px 25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "39px",
                          cursor: "pointer",
                        }}
                      >
                        Logout
                      </div>
                    </div>
                    {/* <BellOutlined style={{ fontSize: "20px", color: "#999999" }} /> */}
                  </Space>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  return (
    <>{window.location.href.split("/").pop() !== "" ? conditionalNav() : ""}</>
  );
};

export default Nav;
