import { Button, Divider, Space } from "antd";
import React, { useContext } from "react";

import { MyContext } from "../../Context";

const Collector = () => {
  const {
    getSubscribtions,
    editable,
    setEditable,
    userInfo,
    coverImage,
    setCoverImage,
    coverImageS3url,
    setCoverImageS3url,
    profileImageS3url,
    setProfileImageS3url,
    setProfileUsername,
    // setLoading,
    uname,
    setUname,
    ubio,
    setUbio,
    udob,
    setUdob,
    bgImg,
    userEmail,
    tokenValid,
    profileUsername,
    userCity,
    setUserCity,
    selectedProfileTab,
    setShowGroups,
  } = useContext(MyContext);
  return (
    <>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "70vh",
          justifyContent: "space-between",
        }}
      > */}
      {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: `3.5vh 0px`,
              borderBottom: "0.5px solid #EDEDED",
            }}
          >
            <div className="tab-item1">Connections</div>
            <div className="tab-item2">135</div>
          </div> */}
      <Space
        direction="vertical"
        split={<Divider type="horizontal" />}
        style={{ width: "100%", padding: "0px 30px", paddingTop: "4vh" }}
      >
        <div className="unselected-side-menu">
          <div className="tab-item1 hoverstyle1">Connections</div>
          <div className="tab-item2">
            {getSubscribtions.data
              ? getSubscribtions.data.get_all_my_subscriptions.length
              : 0}
          </div>
        </div>
        <div className="unselected-side-menu">
          <div className="tab-item1 hoverstyle1">Groups</div>
          <div className="tab-item2">4</div>
        </div>
        <div className="unselected-side-menu" style={{ opacity: "0.3" }}>
          <div className="tab-item1 hoverstyle1">AccessCoins</div>
          <div className="tab-item2">0</div>
        </div>
        <div className="unselected-side-menu" style={{ opacity: "0.3" }}>
          <div className="tab-item1 hoverstyle1">Products</div>
          <div className="tab-item2">0</div>
        </div>
        <div className="unselected-side-menu" style={{ opacity: "0.3" }}>
          <div className="tab-item1 hoverstyle1">Events</div>
          <div className="tab-item2">0</div>
        </div>
        <div style={{ paddingTop: "30px", width: "100%" }}>
          {/* <Button className="tab-button1">Edit Profile</Button> */}
          {localStorage.getItem("user_account") ===
          localStorage.getItem("selected_user_email") ? (
            <Button className="tab-button1" onClick={(e) => setEditable(true)}>
              Edit Profile
            </Button>
          ) : (
            <Button
              className="tab-button1"
              onClick={(e) => {
                selectedProfileTab("Creator");
                setShowGroups(true);
              }}
            >
              Connect
            </Button>
          )}
        </div>
      </Space>
      {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: `3.5vh 0px`,
              borderBottom: "0.5px solid #EDEDED",
            }}
          >
            <div className="tab-item1">Drops</div>
            <div className="tab-item2">4</div>
          </div> */}
      {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: `3.5vh 0px`,
              borderBottom: "0.5px solid #EDEDED",
            }}
          >
            <div className="tab-item1">Interactions</div>
            <div className="tab-item2">4</div>
          </div> */}

      {/* </div> */}
    </>
  );
};

export default Collector;
