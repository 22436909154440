import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { MyContext } from "../../Context";

import {
  Card,
  Tag,
  Tooltip,
  Divider,
  Space,
  Button,
  message,
  Modal,
  Row,
  Col,
  Input,
  Avatar,
} from "antd";
import { EditOutlined, StarFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

const UPDATE_USER = gql`
  mutation updtUsr(
    $user_id: ID!
    $email: String!
    $name: String
    $bio: String
    $long_bio: String
    $dob: String
    $profile: String
    $cover: String
    $video_id: ID
    $thumbnail: String
    $video_title: String
    $video_subtitle: String
    $video_description: String
    $city: String
  ) {
    update_user(
      data: {
        user_id: $user_id
        email: $email
        name: $name
        bio: $bio
        long_bio: $long_bio
        date_of_birth: $dob
        profile_image: $profile
        cover_image: $cover
        video_id: $video_id
        thumbnail: $thumbnail
        video_title: $video_title
        video_subtitle: $video_subtitle
        video_description: $video_description
        city: $city
      }
    ) {
      id
      name
      username
      bio
      profile_image
      cover_image
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
      city
    }
  }
`;

const GET_TIERS = gql`
  query getT($user_id: ID!) {
    get_my_tiers(data: { user_id: $user_id }) {
      id
      name
      description
      subscribed_user_count
      price
    }
  }
`;

const UPDATE_TIER = gql`
  mutation updtUsr($user_id: ID!, $email: ID!, $id: ID!, $desc: String!) {
    update_tier(
      data: { user_id: $user_id, email: $email, description: $desc, id: $id }
    )
  }
`;

const CREATE_TIER = gql`
  mutation createTierT(
    $user_id: ID!
    $email: ID!
    $name: String!
    $desc: String!
    $price: Float!
    $type: String
  ) {
    create_tier(
      data: {
        user_id: $user_id
        email: $email
        name: $name
        description: $desc
        price: $price
        tier_type: $type
      }
    )
  }
`;

const GET_SUBSCRIBED_TIERS = gql`
  query getUser($user_id: ID!, $to_be_subscribed_user_id: ID!) {
    get_subscribed_tier_list(
      data: {
        user_id: $user_id
        to_be_subscribed_user_id: $to_be_subscribed_user_id
      }
    ) {
      tier_id
    }
  }
`;

const SUBSCRIBE_USER = gql`
  mutation subs(
    $user_id: ID!
    $myEmail: ID!
    $to_be_subscribed_user_id: ID!
    $userEmail: ID!
    $tierID: ID!
    $currency: String!
  ) {
    subscribe_to_an_user(
      data: {
        user_id: $user_id
        email: $myEmail
        to_be_subscribed_user_id: $to_be_subscribed_user_id
        to_be_subscribed_user_email: $userEmail
        tier_id: $tierID
        currency: $currency
      }
    )
  }
`;

const CONTACTS = gql`
  query getT($user_id: ID!) {
    get_my_active_subscribers(data: { user_id: $user_id }) {
      who {
        id
        name
        username
        profile_image
      }
    }
  }
`;

const { TextArea } = Input;
const { Meta } = Card;
const Creator = () => {
  const {
    editable,
    setEditable,
    userInfo,
    coverImage,
    setCoverImage,
    coverImageS3url,
    setCoverImageS3url,
    profileImageS3url,
    setProfileImageS3url,
    setProfileUsername,
    // setLoading,
    uname,
    setUname,
    ubio,
    setUbio,
    udob,
    setUdob,
    bgImg,
    userEmail,
    tokenValid,
    profileUsername,
    userCity,
    setUserCity,
    creatorView,
    setCreatorView,
    prof,
  } = useContext(MyContext);
  const [selectedTierForUpdate, setSelectedTierForUpdate] = useState(null);
  const [selectedTierForJoin, setSelectedTierForJoin] = useState(null);
  const [updateUser, updateUserData] = useMutation(UPDATE_USER);
  const [subscribeUser, subscribeUserData] = useMutation(SUBSCRIBE_USER);
  const [visibleUpdateTier, setVisibleUpdateTier] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showEditGroup, setShowEditGroup] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);

  const [tierName, setTierName] = useState("");
  const [tierDesc, setTierDesc] = useState("");
  const [tierPrice, setTierPrice] = useState(null);

  const [contactsQuery, setContactsQuery] = useState("");

  const [updateTier, updateTierData] = useMutation(UPDATE_TIER);
  const [createTier, createTierData] = useMutation(CREATE_TIER);

  const userTiers = useQuery(GET_TIERS, {
    variables: {
      user_id: localStorage.getItem("selected_user_id"),
    },
  });

  const getContacts = useQuery(CONTACTS, {
    variables: {
      user_id: localStorage.getItem("selected_user_id"),
    },
  });

  let filteredContacts = getContacts.data
    ? getContacts.data.get_my_active_subscribers.filter((item) => {
        const lowquery = contactsQuery.toLowerCase();

        return item.who.name.toLowerCase().indexOf(lowquery) >= 0;
      })
    : "";

  const subscribedTiers = useQuery(GET_SUBSCRIBED_TIERS, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
      to_be_subscribed_user_id: localStorage.getItem("selected_user_id"),
      // email: localStorage.getItem("user_account"),
      // userEmail: localStorage.getItem("user_profile_email"),
    },
  });

  const [subTiers, setSubTiers] = useState([]);

  useEffect(() => {
    if (subscribedTiers.data) {
      setSubTiers(subscribedTiers.data.get_subscribed_tier_list);
    }
  }, [subscribedTiers.data]);

  const handleUpdateTier = () => {
    if (tierDesc !== "") {
      updateTier({
        variables: {
          user_id: localStorage.getItem("pulse_user_id"),
          email: localStorage.getItem("user_account"),
          id: selectedTierForUpdate.id,
          desc: tierDesc,
        },
        refetchQueries: [
          {
            query: GET_TIERS,
            variables: { user_id: localStorage.getItem("pulse_user_id") },
          },
        ],
        awaitRefetchQueries: true,
      });
      if (!updateTierData.loading) {
        setTierDesc("");
        setShowEditGroup(false);
        // setVisibleUpdateTier(false);
        // descChangeRef.current.state.val
      }
    }
  };

  const handleCreateTier = () => {
    if (tierName !== "" && tierDesc !== "" && tierPrice !== null) {
      createTier({
        variables: {
          user_id: localStorage.getItem("pulse_user_id"),
          email: userEmail,
          name: tierName,
          desc: tierDesc,
          price: Number(tierPrice),
          type: undefined,
        },
        refetchQueries: [
          {
            query: GET_TIERS,
            variables: { user_id: localStorage.getItem("pulse_user_id") },
          },
        ],
        awaitRefetchQueries: true,
      });
      if (!updateTierData.loading) {
        setTierName("");
        setTierDesc("");
        setTierPrice("");

        setShowCreateGroup(false);
        // setVisibleUpdateTier(false);
        // descChangeRef.current.state.val
      }
    }
  };

  const handleDeleteGroup = () => {};

  const conditionalTierDisplay = () => {
    if (
      localStorage.getItem("selected_user_id") ===
      localStorage.getItem("pulse_user_id")
    ) {
      if (userTiers.data) {
        return (
          userTiers.data.get_my_tiers
            // .sort((a, b) => (a.price > b.price ? 1 : -1))
            .map((item, index) => (
              <>
                <Card
                  style={{ borderStyle: "none" }}
                  className={
                    selectedTierForUpdate !== item
                      ? "tier-card"
                      : "tier-card-subscribed"
                  }
                  onClick={(e) => {
                    setSelectedTierForUpdate(item);
                  }}
                >
                  <Meta
                    avatar={
                      <EditOutlined
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setSelectedTierForUpdate(item);
                          setVisibleUpdateTier(true);
                        }}
                      />
                    }
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{item.name.toUpperCase()}</span>
                        <span>${item.price}</span>
                      </div>
                    }
                    description={
                      <>
                        {item.description}
                        <br />
                        {item.tier_type === "trading_floor" ? (
                          <Tag>Trading Floor</Tag>
                        ) : (
                          ""
                        )}
                      </>
                    }
                  />
                </Card>

                {index + 1 !== userTiers.data.get_my_tiers.length ? (
                  <Divider dashed style={{ margin: "0px" }} />
                ) : (
                  ""
                )}
              </>
            ))
        );
      }
    } else {
      if (userTiers.data) {
        return (
          <div>
            {userTiers.data.get_my_tiers.map((item, index) => (
              <>
                <div
                  className={
                    selectedTierForJoin !== item
                      ? "tier-card"
                      : "tier-card-subscribed"
                  }
                  onClick={(e) => {
                    setSelectedTierForJoin(item);
                  }}
                  style={{ padding: "15px 20px" }}
                  // onClick={e =>
                  //   message.info(
                  //     "Please click the Subscribe ✔ button under Profile to Subscribe to this tier"
                  //   )
                  // }
                  // onClick={(e) => handleSelectTier(item)}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h6>{item.name}</h6>
                    <h6>${item.price}</h6>
                  </div>
                  <p>{item.description}</p>
                  <br />
                  {item.tier_type === "trading_floor" ? (
                    <Tooltip title="This is a Trading Floor Tier">
                      <StarFilled style={{ color: "orange" }} />
                      &nbsp;
                    </Tooltip>
                  ) : item.tier_type === null ? (
                    <Tooltip title="This is a Free Tier">
                      <StarFilled />
                      &nbsp;
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tag style={{ marginTop: "5px" }}>
                    Subscribers: {item.subscribed_user_count}
                  </Tag>
                  {subscribedTiers.data ? (
                    subscribedTiers.data.get_subscribed_tier_list.find(
                      (o) => o.tier_id === item.id
                    ) ? (
                      <Tag color="geekblue">
                        <small style={{ fontWeight: "bold" }}>SUBSCRIBED</small>
                      </Tag>
                    ) : (
                      // <span>✔</span>
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                {index + 1 !== userTiers.data.get_my_tiers.length ? (
                  <Divider dashed style={{ margin: "0px" }} />
                ) : (
                  ""
                )}
              </>
            ))}
          </div>
        );
      }
    }
  };

  const handleUpdateUser = () => {
    updateUser({
      variables: {
        user_id: localStorage.getItem("pulse_user_id"),
        email: localStorage.getItem("user_account"),
        name: uname ? uname : undefined,
        username: profileUsername ? profileUsername : undefined,
        bio: ubio ? ubio : undefined,
        dob: udob ? udob : undefined,
        profile: profileImageS3url ? profileImageS3url : undefined,
        cover: coverImageS3url ? coverImageS3url : undefined,
        city: userCity ? userCity : undefined,
      },
    });
    setEditable(!updateUserData.loading ? false : true);
    setCoverImage(bgImg);
  };

  useEffect(() => {
    if (subscribeUserData.data) {
      if (
        subscribeUserData.data.subscribe_to_an_user === "Subscription Success"
      ) {
        message.success("Subscription Success");
        setCreatorView(null);
      } else {
        message.error(subscribeUserData.data.subscribe_to_an_user);
      }
    }
  }, [subscribeUserData]);

  const handleSubscribe = () => {
    if (subTiers.find((o) => o.tier_id === selectedTierForJoin.id)) {
      message.info("You have already subscribed to this tier.");
    } else {
      subscribeUser({
        variables: {
          user_id: localStorage.getItem("pulse_user_id"),
          myEmail: localStorage.getItem("user_account"),
          to_be_subscribed_user_id: localStorage.getItem("selected_user_id"),
          userEmail: localStorage.getItem("selected_user_email"),
          tierID: selectedTierForJoin.id,
          currency: "USD",
        },
        refetchQueries: [
          {
            query: GET_TIERS,
            variables: {
              user_id: localStorage.getItem("selected_user_id"),
            },
          },
          {
            query: GET_SUBSCRIBED_TIERS,
            variables: {
              user_id: localStorage.getItem("pulse_user_id"),
              to_be_subscribed_user_id:
                localStorage.getItem("selected_user_id"),
            },
          },
          {
            query: CONTACTS,
            variables: {
              user_id: localStorage.getItem("selected_user_id"),
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      // if (selectedTierForJoin.price === 0) {
      //   subscribeUser({
      //     variables: {
      //       user_id: localStorage.getItem("pulse_user_id"),
      //       myEmail: localStorage.getItem("user_account"),
      //       to_be_subscribed_user_id: localStorage.getItem("selected_user_id"),
      //       userEmail: localStorage.getItem("selected_user_email"),
      //       tierID: selectedTierForJoin.id,
      //       currency: "USD",
      //     },
      //     refetchQueries: [
      //       {
      //         query: GET_TIERS,
      //         variables: {
      //           user_id: localStorage.getItem("selected_user_id"),
      //         },
      //       },
      //       {
      //         query: GET_SUBSCRIBED_TIERS,
      //         variables: {
      //           user_id: localStorage.getItem("pulse_user_id"),
      //           to_be_subscribed_user_id:
      //             localStorage.getItem("selected_user_id"),
      //         },
      //       },
      //     ],
      //     awaitRefetchQueries: true,
      //   });

      //   if (subscribeUserData.error) {
      //     console.log("subscribeUserData", subscribeUserData);
      //     message.destroy();
      //     message.success(
      //       `Susscessfully Subscribed to ${selectedTierForJoin.name}`
      //     );
      //     setSelectedTierForJoin(null);
      //     setCreatorView(null);
      //   }
      // } else {
      //   message.error("Paid Groups are not done yet");
      //   // const thingsToPurchase = [
      //   //   {
      //   //     title: "Subscribing to " + selectedTierForUpdate.name,
      //   //     subtitle: "of someone",
      //   //     rate: selectedTierForUpdate.price,
      //   //   },
      //   // ];
      //   // setBuyingFromPulse(true);
      //   // payWithSidebarOpen(
      //   //   thingsToPurchase,
      //   //   "0",
      //   //   parseInt(0),
      //   //   callBack,
      //   //   {
      //   //     title: "Thank you for Subscribing",
      //   //     subTitle: "Click Close to continue",
      //   //   } //Title For Thanks Page
      //   // );
      // }
    }
  };

  const conditionalView = () => {
    if (creatorView === "groups") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "80vh",
          }}
        >
          <div>{conditionalTierDisplay()}</div>

          <div style={{ padding: "30px" }}>
            {tokenValid ? (
              selectedTierForJoin ? (
                <div style={{ width: "100%" }}>
                  <Button
                    className="tab-button-red"
                    type="danger"
                    ghost
                    onClick={(e) => {
                      setCreatorView(null);
                      setSelectedTierForUpdate(null);
                    }}
                    // onClick={(e) => setSelectedTierForUpdate(null)}
                    // onClick={(e) => setShowGroups(true)}
                  >
                    Go Back
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="tab-button1"
                    onClick={(e) => handleSubscribe(true)}
                  >
                    Join Group
                  </Button>
                </div>
              ) : selectedTierForUpdate ? (
                <div style={{ width: "100%" }}>
                  {/* <div>
                <Button
                  type="danger"
                  className="tab-button1"
                  // onClick={(e) => window.history.back()}
                  // onClick={(e) => setSelectedTierForUpdate(null)}
                  onClick={(e) => handleDeleteGroup(selectedTierForUpdate)}
                >
                  Delete Selected Group
                </Button>
               
              </div> */}
                  <Button
                    className="tab-button1"
                    // onClick={(e) => window.history.back()}
                    // onClick={(e) => setSelectedTierForUpdate(null)}
                    onClick={(e) => setShowEditGroup(true)}
                  >
                    Edit Selected Group
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="tab-button1"
                    // onClick={(e) => window.history.back()}
                    // onClick={(e) => setSelectedTierForUpdate(null)}
                    onClick={(e) => {
                      setCreatorView(null);
                      setSelectedTierForJoin(false);
                      setSelectedTierForUpdate(false);
                    }}
                  >
                    Go Back
                  </Button>
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  {localStorage.getItem("pulse_user_id") ===
                  localStorage.getItem("selected_user_id") ? (
                    <Button
                      className="tab-button1"
                      // onClick={(e) => window.history.back()}
                      // onClick={(e) => setSelectedTierForUpdate(null)}
                      onClick={(e) => setShowCreateGroup(true)}
                    >
                      Create New Group
                    </Button>
                  ) : (
                    ""
                  )}
                  &nbsp;&nbsp;
                  <Button
                    className="tab-button1"
                    // onClick={(e) => window.history.back()}
                    // onClick={(e) => setSelectedTierForUpdate(null)}
                    onClick={(e) => {
                      setCreatorView(null);
                      setSelectedTierForJoin(false);
                      setSelectedTierForUpdate(false);
                    }}
                  >
                    Go Back
                  </Button>
                </div>
              )
            ) : (
              <Button
                className="tab-button-red"
                type="danger"
                ghost
                onClick={(e) => {
                  setCreatorView(null);
                  setSelectedTierForUpdate(null);
                }}
                // onClick={(e) => setSelectedTierForUpdate(null)}
                // onClick={(e) => setShowGroups(true)}
              >
                Go Back
              </Button>
            )}
          </div>
        </div>
      );
    } else if (creatorView === "contacts") {
      return (
        <div>
          <Input
            style={{ padding: "8px" }}
            type="search"
            placeholder="Search Contact..."
            value={contactsQuery}
            onChange={(e) => setContactsQuery(e.target.value)}
          />
          <div style={{ padding: "5px" }}>
            {filteredContacts.map((item) => {
              return (
                <div
                  style={{ padding: "5px" }}
                  onClick={(e) => setCreatorView(null)}
                >
                  <Link
                    to={`/${item.who.username}`}
                    style={{ color: "#000000a6" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px 10px",
                      }}
                    >
                      <Link
                        to={`/${item.who.username}`}
                        style={{ color: "#000000a6" }}
                      >
                        <div
                          onClick={(e) => {
                            localStorage.setItem(
                              "user_profile_email",
                              item.who.email
                            );
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            {item.who.profile_image ? (
                              <Avatar
                                className="shadow-down"
                                src={item.who.profile_image}
                                size={38}
                              />
                            ) : (
                              <Avatar src={prof} size={38} />
                            )}
                          </div>
                          &nbsp;&nbsp;
                          <div
                            onClick={(e) => {
                              localStorage.setItem(
                                "user_profile_email",
                                item.who.email
                              );
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {item.who.name}
                            </span>
                            <br />
                            {/* <span>{item.username}</span> */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>

          {/* {getContacts.data
            ? getContacts.data.get_my_active_subscribers.map((item) => {
                return <div>{item.who.name}</div>;
              })
            : ""} */}
        </div>
      );
    } else {
      return (
        <Space
          direction="vertical"
          split={<Divider type="horizontal" />}
          style={{ width: "100%", padding: "0px 30px", paddingTop: "4vh" }}
        >
          <div
            className="unselected-side-menu"
            onClick={(e) => setCreatorView("contacts")}
          >
            <div className="tab-item1 hoverstyle1">Contacts</div>
            <div className="tab-item2">
              {getContacts.data
                ? getContacts.data.get_my_active_subscribers.length
                : 0}
            </div>
          </div>
          <div
            className="unselected-side-menu"
            onClick={(e) => setCreatorView("groups")}
          >
            <div className="tab-item1 hoverstyle1">Groups</div>
            <div className="tab-item2">
              {userTiers.data ? userTiers.data.get_my_tiers.length : 0}
            </div>
          </div>
          <div className="unselected-side-menu" style={{ opacity: "0.3" }}>
            <div className="tab-item1 hoverstyle1">AccessCoins</div>
            <div className="tab-item2">0</div>
          </div>
          <div className="unselected-side-menu" style={{ opacity: "0.3" }}>
            <div className="tab-item1 hoverstyle1">Endorsements</div>
            <div className="tab-item2">0</div>
          </div>
          {/* <div className="unselected-side-menu">
        <div className="tab-item1 hoverstyle1">Events</div>
        <div className="tab-item2">4</div>
      </div> */}
          <div className="unselected-side-menu" style={{ opacity: "0.3" }}>
            <div className="tab-item1 hoverstyle1">Drops</div>
            <div className="tab-item2">0</div>
          </div>

          <div style={{ paddingTop: "30px", width: "100%" }}>
            {tokenValid ? (
              editable ? (
                <div>
                  <Button
                    type="danger"
                    ghost
                    className="tab-button-red"
                    onClick={(e) => {
                      setEditable(false);
                      setCoverImageS3url("");
                      setProfileImageS3url("");
                      setUname("");
                      setUbio("");
                      setProfileUsername("");
                    }}
                    style={{ color: "red" }}
                  >
                    ❌ Cancel
                  </Button>
                  &nbsp;
                  <Button
                    className="tab-button1"
                    onClick={handleUpdateUser}
                    loading={loading}
                  >
                    ✔ Save
                  </Button>
                  {/* <Button className="tab-button1" onClick={(e) => setEditable(false)}>
            Save Profile
          </Button> */}
                </div>
              ) : localStorage.getItem("user_account") ===
                localStorage.getItem("selected_user_email") ? (
                <Button
                  className="tab-button1"
                  onClick={(e) => setEditable(true)}
                >
                  Edit Profile
                </Button>
              ) : (
                <Button
                  className="tab-button1"
                  onClick={(e) => setCreatorView("groups")}
                >
                  Connect
                </Button>
              )
            ) : (
              <Link to="/home">
                <Button
                  className="tab-button1"
                  // onClick={(e) => setShowGroups(true)}
                >
                  Login
                </Button>
              </Link>
            )}
          </div>
        </Space>
      );
    }
  };

  return (
    <>
      {conditionalView()}
      {/* Create Group */}
      <Modal
        destroyOnClose
        title="Add New Tier"
        footer={null}
        visible={showCreateGroup}
        // onOk={this.handleOk}
        onCancel={(e) => setShowCreateGroup(false)}
      >
        <Row gutter={14}>
          <Col span={18}>
            <Input
              type="text"
              placeholder="Enter Tier Name"
              value={tierName}
              onChange={(e) => setTierName(e.target.value)}
              //   ref={nameRef}
            />
          </Col>
          <Col span={6}>
            <Input
              type="text"
              placeholder="Tier Price"
              prefix={"$"}
              //   ref={priceRef}
              value={tierPrice}
              onChange={(e) =>
                setTierPrice(e.target.value.replace(/[^0-9.]/g, ""))
              }
            />
          </Col>
        </Row>
        <TextArea
          style={{ marginTop: "10px" }}
          rows={4}
          //   ref={descRef}
          value={tierDesc}
          onChange={(e) => setTierDesc(e.target.value)}
          placeholder={
            selectedTierForUpdate
              ? selectedTierForUpdate.description
              : "Tier Description"
          }
          // style={{ borderStyle: "none" }}
        />
        {/* <Checkbox
          style={{ display: tierFlag ? "none" : "block" }}
          onChange={(e) => setTierType(e.target.checked)}
        >
          Make this Trading Floor Tier
        </Checkbox> */}
        <Button
          loading={createTierData.loading}
          type="primary"
          block
          style={{ marginTop: "10px" }}
          onClick={handleCreateTier}
        >
          CREATE TIER
        </Button>
      </Modal>

      {/* Update Group */}
      <Modal
        destroyOnClose
        title="Update Tier Description"
        footer={null}
        visible={showEditGroup}
        // onOk={this.handleOk}
        onCancel={(e) => setShowEditGroup(false)}
      >
        <TextArea
          rows={4}
          // ref={descChangeRef}
          value={tierDesc}
          onChange={(e) => setTierDesc(e.target.value)}
          placeholder={
            selectedTierForUpdate
              ? selectedTierForUpdate.description
              : "Tier Description"
          }
          // style={{ borderStyle: "none" }}
        />
        <div>
          <Button
            type="primary"
            block
            style={{ marginTop: "10px" }}
            onClick={handleUpdateTier}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Creator;
