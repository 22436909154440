import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useNavigate, Link } from "react-router-dom";
import { gql } from "apollo-boost";
import {
  Row,
  Col,
  Card,
  Icon,
  Divider,
  Avatar,
  Tag,
  message,
  Input,
  Button,
} from "antd";
import ReactPlayer from "react-player";
import PostComponent from "../components/PostComponent";
import prof from "../img/user.svg";
import { MyContext } from "../Context";
import Axios from "axios";

import {
  EditOutlined,
  DeleteOutlined,
  HeartOutlined,
  PlusOutlined,
  EyeOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const LIKE_POST = gql`
  mutation lkpost($id: ID!, $email: String!, $dislikeflag: Boolean!) {
    like_dislike_for_post(
      data: { post_id: $id, email: $email }
      dislike: $dislikeflag
    )
  }
`;

const CREATE_COMMENT = gql`
  mutation cmt($id: ID!, $email: String!, $text: String!) {
    create_comment(data: { post_id: $id, email: $email, text: $text }) {
      like_count
      text
      id
    }
  }
`;

const GET_COMMENT = gql`
  query getcmt($email: ID!, $id: ID!) {
    get_comments_of_a_post(data: { email: $email, post_id: $id }) {
      id
      like_count
      text
      timestamp
      author {
        name
        profile_image
      }
    }
  }
`;

const NEWS_FEED = gql`
  query ($email: String!) {
    get_my_home_posts(data: { email: $email }) {
      id
      title
      body
      locked
      post_tier {
        id
        name
      }
      timestamp
      author {
        username
        id
        profile_image
        email
      }
      comments {
        text
        author {
          id
          username
          profile_image
          email
        }
      }
      liked_users {
        id
        email
        username
      }
      comment_count
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const GET_POST = gql`
  query getPost($user_id: ID!, $id: ID!) {
    get_single_post(data: { user_id: $user_id, post_id: $id }) {
      id
      title
      body
      timestamp
      like_count
      post_tier {
        id
        name
      }
      comments {
        text
      }
      author {
        id
        name
        username
        profile_image
        email
      }
      comment_count
      liked_users {
        id
        username
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
      view_count
    }
  }
`;

const GET_ALL_USERS = gql`
  query {
    get_all_users {
      id
      email
      name
      username
      profile_image
    }
  }
`;

const LoggedOutVideo = () => {
  const history = useNavigate();
  const { streamingUrl } = useContext(MyContext);
  const [showCommentArr, setShowCommentArr] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const [postComments, setPostComments] = useState([]);

  const [likedUsers, setLikedUsers] = useState(["ichiranjeeb"]);
  const [userComments, setUserComments] = useState();

  const [visibleProfileCard, setVisibleProfileCard] = useState(false);

  const [likeDislikePost, likeDislikePostData] = useMutation(LIKE_POST);
  const [commentOnPost, commentOnPostData] = useMutation(CREATE_COMMENT);
  const [getComment, getCommentData] = useLazyQuery(GET_COMMENT);
  const [myVideoURL, setMyVideoURL] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [commentSectionFlag, setCommentSectionFlag] = useState();
  const [allComments, setAllComments] = useState([]);

  const [postData, setPostData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [trendData, setTrendData] = useState(null);

  const getAllUsers = useQuery(GET_ALL_USERS);

  const getPost = useQuery(GET_POST, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
      id: window.location.href.split("/").pop(),
      // email: localStorage.getItem("user_account"),
      // id: history.location.pathname.split("/").pop(),
    },
  });

  useEffect(() => {
    console.log(window.location.href.split("/").pop(), "postidddd");
    const postID = Axios.get(
      `https://connectionsapi.globalxchange.io/get_single_post?id=${window.location.href
        .split("/")
        .pop()}`
    ).then((res) => {
      console.log(
        res.data.payload.posts_data[0],
        res.data.payload,
        "postiddddxx"
      );
      setPostData(res.data.payload.posts_data[0]);
      setUserData(res.data.payload.user_data);
      setTrendData(res.data.payload.trends);

      setThumbnail(res.data.payload.posts_data[0].thumbnail);
      setTitle(res.data.payload.posts_data[0].video_title);
      setDescription(res.data.payload.posts_data[0].video_description);
      getUrl(
        res.data.payload.posts_data[0].video_id,
        res.data.payload.posts_data[0].id
      );
    });
  }, []);

  const allPosts = useQuery(NEWS_FEED, {
    variables: {
      email: localStorage.getItem("user_account"),
    },
  });
  const getUrl = (videoID, postID) => {
    Axios.post(streamingUrl + `get_stream_link_video_post_public`, {
      video_id: videoID,
      post_id: postID,
    }).then((res) => {
      setMyVideoURL(res.data);
    });
  };

  const getLikeCount = () => {
    if (getPost.data) {
      Axios.post(
        streamingUrl + `like_count`,
        {
          video_id: getPost.data
            ? getPost.data.get_single_post[0].video_id
            : "",
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      ).then((res) => {
        setLikeCount(res.data.payload);
        console.log("Like Count is = ", res.data.payload);
      });
    }
  };

  const getCommentCount = () => {
    if (getPost.data) {
      Axios.post(
        streamingUrl + `comment_count`,
        {
          video_id: getPost.data
            ? getPost.data.get_single_post[0].video_id
            : "",
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      ).then((res) => {
        setCommentCount(res.data.payload);
        console.log("Comment Count is = ", res.data.payload);
      });
    }
  };

  useEffect(() => {
    if (getPost.data) {
      getLikeCount();
      getCommentCount();
    }
  }, [getPost]);

  const addComment = () => {
    setLoading(true);
    Axios.post(
      streamingUrl + `comment_a_video`,
      {
        video_id: getPost.data ? getPost.data.get_single_post[0].video_id : "",
        source: "pulse",
        comment: commentInput,
      },
      {
        headers: {
          email: localStorage.getItem("user_account"),
          token: localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      if (res.data.status) {
        message.success(res.data.payload);
        setCommentInput("");
        setLoading(false);
        getCommentCount();
        getComments();
      } else {
        message.error(res.data.payload);
      }
    });
  };

  const getComments = () => {
    Axios.post(
      streamingUrl + `all_comments`,
      {
        video_id: getPost.data ? getPost.data.get_single_post[0].video_id : "",
      },
      {
        headers: {
          email: localStorage.getItem("user_account"),
          token: localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      setAllComments(res.data.payload);
      console.log("all_comments", res.data.payload);
    });
  };

  const deleteComment = (element) => {
    Axios.post(
      streamingUrl + `delete_comment_of_a_video`,
      {
        comment_id: element.id,
      },
      {
        headers: {
          email: localStorage.getItem("user_account"),
          token: localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      if (res.data.status) {
        message.success(res.data.payload);
        getCommentCount();
        getComments();
      } else {
        message.error(res.data.payload);
      }
    });
  };

  useEffect(() => {
    if (commentSectionFlag) {
      getComments();
    }
  }, [commentSectionFlag]);

  const getUserDetails = (element) => {
    if (getAllUsers.data) {
      return getAllUsers.data.get_all_users.map((item) => {
        if (item.email === element.email) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Avatar
                  src={item.profile_image ? item.profile_image : prof}
                  size={30}
                />
              </div>
              &nbsp;
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0px",
                }}
              >
                <p
                  style={{
                    backgroundColor: "#F5F5F5",
                    padding: "5px",
                    borderRadius: "8px",
                    margin: "0px",
                  }}
                >
                  <span
                    style={{
                      color: "#40A9FF",
                      fontWeight: "bold",
                    }}
                  >
                    {item.name}
                  </span>
                  : {element.comment}
                </p>
              </div>
              &nbsp;
              <div>
                {localStorage.getItem("user_account") === element.email ? (
                  <div
                    onClick={(e) => deleteComment(element)}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DeleteOutlined
                      style={{ cursor: "pointer", color: "#FFCCBC" }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        }
      });
    }
  };

  // useEffect(() => {
  //   if (getPost.data) {
  //     setLikedUsers(getPost.data.get_single_post[0].liked_users);
  //   }
  // }, [getPost.data]);

  // const handleLike = item => {
  //   if (likedUsers.length > 0) {
  //     if (likedUsers.indexOf(localStorage.getItem("pulse_user_id")) > -1) {
  //       likeDislikePost({
  //         variables: {
  //           id: item.id,
  //           email: localStorage.getItem("user_account"),
  //           dislikeflag: true
  //         }
  //       });
  //     } else {
  //       likeDislikePost({
  //         variables: {
  //           id: item.id,
  //           email: localStorage.getItem("user_account"),
  //           dislikeflag: false
  //         }
  //       });
  //     }
  //   } else {
  //     likeDislikePost({
  //       variables: {
  //         id: item.id,
  //         email: localStorage.getItem("user_account"),
  //         dislikeflag: false
  //       }
  //     });
  //   }
  // };

  return (
    <Row style={{ paddingTop: "30px" }}>
      <Col span={4}></Col>
      <Col xs={24} sm={16} className="makeacard">
        {postData ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              //   paddingBottom: "15px",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowLeftOutlined
                style={{
                  display: window.history.length > 1 ? "block" : "none",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginTop: "9px",
                }}
                onClick={(e) => window.history.back()}
              />
              <span
                style={{
                  display: window.history.length > 1 ? "block" : "none",
                }}
              >
                &nbsp;&nbsp;
              </span>
              {userData ? (
                userData[postData.author_id].profile_image ? (
                  <Avatar
                    src={userData[postData.author_id].profile_image}
                    size={40}
                  />
                ) : (
                  <Avatar src={prof} size={40} />
                )
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              {/* <Link to={`/${userData[postData.author_id].username}`}> */}
              <div
                onClick={(e) => {
                  localStorage.setItem(
                    "user_profile_email",
                    userData[postData.author_id].email
                  );
                }}
              >
                <h5 style={{ margin: "0px", cursor: "pointer" }}>
                  {userData ? userData[postData.author_id].name : ""}
                </h5>
                <small>
                  {new Date(Number(postData.timestamp)).toDateString()}
                  {/* {new Date(Number(item.timestamp)).toLocaleString()} */}
                </small>
              </div>
              {/* </Link> */}
            </div>
            <div>
              <Tag>Tag</Tag>
              {/* <Tag>{item.post_tier[0].name}</Tag> */}
              {/* <Icon type="setting" style={{ fontSize: "15px" }} /> */}
            </div>
          </div>
        ) : (
          ""
        )}

        <ReactPlayer
          width="100%"
          height="50vh"
          url={myVideoURL}
          // className="videoPlayerSectionWraper"
          // muted={true}
          controls
          playing={true}
          // loop={true}
          light={thumbnail}
        />
        {postData ? (
          <Card
            bodyStyle={{ padding: "0px" }}
            actions={[
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <HeartOutlined />
                &nbsp; <span>Like ({likeCount})</span>
              </span>,
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EyeOutlined />
                &nbsp; <span>View ({postData.view_count})</span>
              </span>,
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EditOutlined />
                &nbsp;{" "}
                <span
                  onClick={(e) => setCommentSectionFlag(!commentSectionFlag)}
                >
                  Comment ({commentCount})
                </span>
              </span>,
            ]}
          >
            <>
              <div style={{ padding: "10px" }}>
                <h5>{postData.video_title}</h5>
                <p>{postData.video_description.substring(0, 300)}...</p>
              </div>
            </>
          </Card>
        ) : (
          ""
        )}
        {commentSectionFlag ? (
          <Card bodyStyle={{ paddingTop: "20px" }}>
            {allComments.map((item) => {
              return <>{getUserDetails(item)}</>;
            })}

            <div style={{ display: "flex", marginTop: "15px" }}>
              <div style={{ width: "100%" }}>
                <Input
                  value={commentInput}
                  onChange={(e) => setCommentInput(e.target.value)}
                  type="text"
                  placeholder="write a comment..."
                  style={{ borderRadius: "100px" }}
                />
              </div>
              &nbsp;
              <div>
                <Button
                  shape="circle"
                  onClick={(e) => addComment()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!loading ? <PlusOutlined /> : <LoadingOutlined />}
                </Button>
              </div>
            </div>
          </Card>
        ) : (
          ""
        )}
      </Col>
      <Col span={4}></Col>
    </Row>
  );
};

export default LoggedOutVideo;
