import { Col, Empty, Spin, Tag, Divider, Card } from "antd";
import React, { useState, useContext, useEffect } from "react";
import MenuSidebar from "../components/MenuSidebar";
import CreatePostComponent from "../components/CreatePostComponent";

import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { MyContext } from "../Context";
import PostComponent from "../components/PostComponent";

const ALL_TRENDS = gql`
  {
    trends {
      id
      name
      post_count
    }
  }
`;

const Home = () => {
  const {
    newsFeed,
    trendClicked,
    getPostsOfTrend,
    resTrendPosts,
    userInfo,
    selectedTrend,
    setSelectedTrend,
    setTrendClicked,
    showMenu,
    showTrend,
    setShowTrend,
  } = useContext(MyContext);

  const allTrends = useQuery(ALL_TRENDS);

  const showConditionalPosts = () => {
    console.log("newsfeed", newsFeed.data);
    if (resTrendPosts.data && trendClicked) {
      // console.log("jhkjhkjhk", value.resTrendPosts.data);
      return resTrendPosts.data ? (
        resTrendPosts.data.user_choosen_trend
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => (
            <PostComponent
              item={item}
              index={index}
              userInfo={userInfo.data ? userInfo : ""}
            />
          ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <p>No Posts</p>
        </div>
      );
    } else if (newsFeed.data && !trendClicked) {
      if (newsFeed.data.get_my_home_posts.length > 0) {
        return newsFeed.data.get_my_home_posts
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => (
            <PostComponent
              item={item}
              index={index}
              userInfo={userInfo.data ? userInfo : ""}
            />
          ));
      } else {
        return (
          <div style={{ padding: "20px" }}>
            <Empty
              description={
                <span>
                  Either you are not subscribed to anyone <br />
                  or there are no posts to show
                </span>
              }
            />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  const showTrends = () => {
    if (allTrends.data) {
      if (allTrends.data.trends.length > 0) {
        return (
          allTrends.data.trends
            // .sort((a, b) => (a.post_count < b.post_count ? 1 : -1))
            .map((item, index) => {
              if (index < 10 && item.post_count > 0) {
                return (
                  <div>
                    <p
                      onClick={() => {
                        setTrendClicked(true);
                        setSelectedTrend({
                          name: item.name,
                          count: item.post_count,
                        });
                        // setTrendPostCount(item.post_count);
                        getPostsOfTrend({
                          variables: {
                            id: item.id,
                            user_id: localStorage.getItem("pulse_user_id"),
                          },
                        });
                      }}
                      style={{
                        margin: "0px",
                        cursor: "pointer",
                        // padding: "5px",
                        // paddingLeft: "25px"
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2px 20px",
                      }}
                    >
                      <span>#{item.name}</span>
                      <span>
                        <Tag>{item.post_count}</Tag>
                      </span>
                    </p>
                    {index !== 9 ? (
                      allTrends.data.trends.length !== index + 1 ? (
                        <Divider dashed style={{ margin: "5px 0px" }} />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {/* {index !== 9 ? (
                    <Divider dashed style={{ margin: "5px 0px" }} />
                  ) : (
                    <Empty />
                  )} */}
                  </div>
                );
              }
            })
        );
      } else {
        return <Empty />;
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  useEffect(() => {
    console.log("showMenu: ", showMenu, "showTrend: ", showTrend);
  }, [showMenu, showTrend]);

  return (
    <>
      {!showMenu && !showTrend ? (
        <Col
          xs={24}
          sm={14}
          style={{
            // display: showMenu ? "none" : "block",
            borderRight: "0.5px solid #EDEDED",
            background: "#ECF0F1",
            overflowY: "scroll",
            height: "98vh",
            padding: " 1px",
          }}
        >
          {!trendClicked ? (
            <CreatePostComponent />
          ) : (
            <div className="trend-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "yellow",
                  }}
                >
                  #
                </span>
                &nbsp;
                <h2 style={{ margin: "0px", color: "white" }}>
                  {selectedTrend.name}
                </h2>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tag
                  color="#fffff"
                  style={{ color: "black" }}
                  className="shadow-down"
                >
                  Post Count: {selectedTrend.count}
                </Tag>
              </div>
            </div>
          )}
          <div style={{ padding: " 20px" }}>{showConditionalPosts()}</div>
        </Col>
      ) : showMenu ? (
        <MenuSidebar />
      ) : (
        ""
      )}
      {window.innerWidth > 900 ? (
        <Col xs={24} sm={6}>
          <Card
            title="Recent Trends"
            bodyStyle={{
              padding: "0px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {showTrends()}
          </Card>
        </Col>
      ) : showTrend ? (
        <Col xs={24} sm={6}>
          <Card
            title="Recent Trends"
            bodyStyle={{
              padding: "0px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {showTrends()}
          </Card>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default Home;
