import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, message } from "antd";
import { Link } from "react-router-dom";

const NewRegistration = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [rUsername, setRusername] = useState("");
  const [rEmail, setRemail] = useState("");
  const [rPassword, setRpassword] = useState("");
  const [rPassword1, setRpassword1] = useState("");
  const [visibleVerify, setVisibleVerify] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [fullPageLoading, setFullPageLoading] = useState(false);
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const [step, setStep] = useState(1);

  const handleRegistration = () => {};

  const checkEmail = () => {
    setStep(2);
    // if (false) {
    //   setStep(2);
    // } else {
    //   message.error("Email Not Found");
    // }
  };

  const conditionalPages = () => {
    if (step === 1) {
      return (
        <Row>
          <Col
            span={8}
            style={{
              background: "#009EFC",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="side-text">Right Now.</div>
            <div className="side-text">We Are</div>
            <div className="side-text">Invite Only</div>
          </Col>
          <Col
            span={16}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100vh",
              padding: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <img
                src={require("../img/Group4.svg")}
                alt=""
                style={{ width: "100%" }}
              />
              <br />
              <br />
              <div className="sub-heading">
                Please Enter You’re Friends NetworkSync Code
              </div>

              <form
                onSubmit={handleRegistration}
                style={{
                  width: "100%",
                  paddingTop: "70px",
                  paddingBottom: "70px",
                }}
              >
                <Input
                  style={{ padding: "0px 30px" }}
                  className="login-input"
                  placeholder="friends@gmail.com...."
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </form>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  //   paddingBottom: "30px",
                }}
              >
                <Button
                  onClick={(e) => window.history.back()}
                  // loading={loading ? true : false}
                  // type="submit"
                  type="ghost"
                  className="small-ghost-button"
                >
                  Go Back
                </Button>

                <div style={{ width: "20px" }}>&nbsp;</div>
                <Button
                  loading={loading ? true : false}
                  // type="submit"
                  type="primary"
                  className="small-primary-button"
                  onClick={checkEmail}
                >
                  Proceed
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      );
    } else if (step === 2) {
      return (
        <Row>
          <Col
            span={8}
            style={{
              background: "#009EFC",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="side-text">Is This Your</div>
            <div className="side-text">Friend →</div>
            {/* <div className="side-text">Invite Only</div> */}
          </Col>
          <Col
            span={16}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100vh",
              padding: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  borderColor: "gray",
                }}
              >
                <img
                  src={require("../img/profile.png")}
                  alt=""
                  style={{ width: "200px" }}
                />
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                <div className="sub-heading" style={{ fontSize: "40px" }}>
                  Shorupan
                </div>
                <br />
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "#999999",
                  }}
                >
                  shorupan@gmail.com
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  //   paddingBottom: "30px",
                  paddingTop: "60px",
                  width: "100%",
                }}
              >
                <Button
                  onClick={(e) => window.history.back()}
                  loading={loading ? true : false}
                  // type="submit"
                  type="ghost"
                  className="small-ghost-button"
                >
                  Go Back
                </Button>

                <div style={{ width: "20px" }}>&nbsp;</div>
                <Button
                  loading={loading ? true : false}
                  // type="submit"
                  type="primary"
                  className="small-primary-button"
                  onClick={handleRegistration}
                >
                  Proceed
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  };

  const conditionalMobilePages = () => {
    if (step === 1) {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              padding: "0px 50px",
            }}
          >
            <div style={{ paddingBottom: "95px" }}>
              <img
                src={require("../img/Group4.png")}
                alt=""
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ paddingBottom: "95px", width: "100%" }}>
              <Input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                className="login-mobile-input1"
                placeholder=" Please Enter You’re Friends NetworkSync Code"
              />
            </div>

            <Button
              onClick={(e) => setStep(2)}
              type="primary"
              disabled={username.length ? false : true}
              className="mobile-login"
              style={{
                marginBottom: "24px",
                background: username.length > 0 ? "#009efc" : "#BFE7FE",
                border:
                  username.length > 0
                    ? "1px solid #009efc"
                    : "1px solid #BFE7FE",
                color: "white",
              }}
            >
              Proceed
            </Button>
            <Button
              onClick={(e) => window.history.back()}
              // loading={loading ? true : false}
              // type="submit"
              type="ghost"
              className="small-ghost-button"
            >
              Go Back
            </Button>
          </div>
        </>
      );
    } else if (step === 2) {
      return (
        <Row>
          <Col
            span={8}
            style={{
              background: "#009EFC",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="side-text">Is This Your</div>
            <div className="side-text">Friend →</div>
            {/* <div className="side-text">Invite Only</div> */}
          </Col>
          <Col
            span={16}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100vh",
              padding: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  borderColor: "gray",
                }}
              >
                <img
                  src={require("../img/profile.png")}
                  alt=""
                  style={{ width: "200px" }}
                />
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                <div className="sub-heading" style={{ fontSize: "40px" }}>
                  Shorupan
                </div>
                <br />
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "#999999",
                  }}
                >
                  shorupan@gmail.com
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  //   paddingBottom: "30px",
                  paddingTop: "60px",
                  width: "100%",
                }}
              >
                <Button
                  onClick={(e) => window.history.back()}
                  loading={loading ? true : false}
                  // type="submit"
                  type="ghost"
                  className="small-ghost-button"
                >
                  Go Back
                </Button>

                <div style={{ width: "20px" }}>&nbsp;</div>
                <Button
                  loading={loading ? true : false}
                  // type="submit"
                  type="primary"
                  className="small-primary-button"
                  onClick={handleRegistration}
                >
                  Proceed
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <>
      {window.innerWidth > 900 ? conditionalPages() : conditionalMobilePages()}
    </>
  );
};

export default NewRegistration;
