import {
  Row,
  Col,
  Input,
  Space,
  Button,
  Menu,
  Dropdown,
  Avatar,
  Popover,
  Divider,
  Tag,
} from "antd";
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  SearchOutlined,
  UserOutlined,
  CaretDownOutlined,
  DownOutlined,
  BellOutlined,
  ArrowRightOutlined,
  MenuOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import { Route, useNavigate, Switch, HashRouter, Link } from "react-router-dom";
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import prof from "../img/user.svg";
import { MyContext } from "../Context";
import Axios from "axios";

const USER_INFO = gql`
  query getUser($user_id: ID) {
    get_user(data: { user_id: $user_id }) {
      id
      name
      username
      email
      bio
      date_of_birth
      profile_image
      cover_image
      following_count
      my_follower_count
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
      long_bio
      user_type_id
      city
      achievements {
        id
        title
        description
        endorsed_users
      }
    }
  }
`;

const GET_ALL_USERS = gql`
  query {
    get_all_users {
      id
      email
      name
      username
      profile_image
    }
  }
`;

const ALL_TRENDS = gql`
  {
    trends {
      id
      name
      post_count
    }
  }
`;

const NavMobile = () => {
  const {
    tokenValid,
    setTrendClicked,
    showMenu,
    setShowMenu,
    showTrend,
    setShowTrend,
  } = useContext(MyContext);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [showDropdown, setShowDropdown] = useState(false);
  const [query, setQuery] = useState("");

  const [count, setCount] = useState(0);
  const handleMenuClick = () => {};
  const navigate = useNavigate();

  const allTrends = useQuery(ALL_TRENDS);
  const getAllUsers = useQuery(GET_ALL_USERS);

  const [getAllUsersApi, setGetAllUsersApi] = useState([]);
  const [allUsername, setAllUsername] = useState([]);
  // const [getAllTrendsApi, setGetAllTrendsApi] = useState([]);

  useEffect(() => {
    Axios.get(`https://connectionsapi.globalxchange.io/get_all_users_api`).then(
      (res) => {
        console.log(res.data, "kjwbfkjwebkfw");
        setGetAllUsersApi([...res.data.names]);
        setAllUsername([...res.data.usernames]);
      }
    );
  }, []);

  var userInfo = useQuery(USER_INFO, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
    },
  });

  let filteredUsers = getAllUsers.data
    ? getAllUsers.data.get_all_users.filter((item) => {
        const lowquery = query.toLowerCase();
        return (item.name + item.username).toLowerCase().indexOf(lowquery) >= 0;
      })
    : "";

  getAllUsersApi.filter((query) => allUsername.includes(query));

  let filteredUsersApi = getAllUsersApi
    ? getAllUsersApi.filter((item) => {
        const lowquery = query.toLowerCase();
        return item.toLowerCase().indexOf(query) >= 0;
      })
    : "";

  // let filteredUsernames = allUsername
  //   ? allUsername.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.toLowerCase().indexOf(query) >= 0;
  //     })
  //   : "";

  let filteredTrends = allTrends.data
    ? allTrends.data.trends.filter((item) => {
        const lowquery = query.toLowerCase();
        return item.name.toLowerCase().indexOf(lowquery) >= 0;
      })
    : "";

  // let filteredTrendsApi = allTrends.data
  //   ? allTrends.data.trends.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.name.toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        console.log("Close logout");
        setShowDropdown(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //   alert("You clicked outside of me!");
          setShowDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  //   useEffect(() => {
  //     console.log("kjwekjfbwkefbw", showDropdown, count);

  //     window.addEventListener("click", () => {
  //       if (count < 2) {
  //         setCount(count + 1);
  //       } else {
  //         setCount(0);
  //       }
  //     });
  //   });

  //   useEffect(() => {
  //     if (showDropdown) {
  //       if (count === 2) {
  //         setShowDropdown(false);
  //         setCount(0);
  //       }
  //     }
  //   }, [count]);

  const conditionalNav = () => {
    if (window.location.href.split("/").pop() !== "login") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <MenuOutlined
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: showMenu ? "#009EFC" : "",
            }}
            onClick={(e) => {
              setShowMenu(!showMenu);
              setShowTrend(false);
            }}
          />
          <div
            onClick={(e) => {
              navigate("/home");
              setTrendClicked(false);
            }}
          >
            <div style={{ padding: "10px 30px" }}>
              <img
                src={require("../img/Group4.svg")}
                alt=""
                className="full-loading-logo"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <NumberOutlined
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              fontStyle: "italic",
              color: showTrend ? "#009EFC" : "",
            }}
            onClick={(e) => {
              setShowTrend(!showTrend);
              setShowMenu(false);
            }}
          />
        </div>
      );
    }
  };

  return (
    <>{window.location.href.split("/").pop() !== "" ? conditionalNav() : ""}</>
  );
};

export default NavMobile;
