import React, { useEffect, useState, useContext } from "react";
import {
  Layout,
  Input,
  Avatar,
  Spin,
  Popover,
  Divider,
  Tag,
  Col,
  Row,
  Space,
  Button,
} from "antd";

import {
  Link,
  useNavigate,
  useLocation,
  Switch,
  Route,
  Outlet,
} from "react-router-dom";

import { MyContext } from "../Context";

import { ReactComponent as Home_img } from "../img/nav-icons/Home.svg";
import { ReactComponent as Saved_img } from "../img/nav-icons/Saved.svg";
import { ReactComponent as Chats_img } from "../img/nav-icons/Chats.svg";
import { ReactComponent as Conn_img } from "../img/nav-icons/Connections.svg";
import { ReactComponent as Profile_img } from "../img/nav-icons/Profile.svg";
import { ReactComponent as Subs_img } from "../img/nav-icons/Subscriptions.svg";
import { ReactComponent as Vaults_img } from "../img/nav-icons/Vaults.svg";
import { ReactComponent as Drops_img } from "../img/nav-icons/Drops.svg";
import { ReactComponent as Interactions_img } from "../img/nav-icons/Interactions.svg";

const MenuSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { tokenValid, userEmail, setTrendClicked } = useContext(MyContext);

  useEffect(() => {
    setSelectedMenu(window.location.href.split("/").pop());
  }, [window.location.href.split("/").pop()]);

  // useEffect(() => {
  //   console.log(location, "kbwcdkhwbed");
  // });

  // const conditionalHighlight = () => {
  //   if(window.location.href.split("/").pop()){

  //   }
  // }

  return window.location.pathname.replace(/^\/([^\/]*).*$/, "$1") ||
    tokenValid ? (
    <Col
      span={
        window.innerWidth > 900
          ? window.location.pathname.replace(/^\/([^\/]*).*$/, "$1") ||
            tokenValid
            ? 4
            : 4
          : 24
      }
      style={{
        // background: "#E5E5E5",
        padding: "10px",
        paddingTop: "20px",
        height: "98vh",
        borderRight: "0.5px solid #EDEDED",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Link to={tokenValid ? "/" : "/"}>
          <div
            onMouseOver={() => setSelectedMenu("home")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "home"
                ? setSelectedMenu(null)
                : ""
            }
            onClick={(e) => {
              setTrendClicked(false);
            }}
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "home"
                  ? "#DEF2FF"
                  : selectedMenu === "home"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Home_img
              fill={
                window.location.href.split("/").pop() === tokenValid
                  ? "home"
                  : ""
                  ? "#009EFC"
                  : selectedMenu === "home"
                  ? "#009EFC"
                  : "#999999"
              }
              stroke="none"
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === ""
                    ? "#009EFC"
                    : selectedMenu === "home"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Pulse
            </div>
          </div>
        </Link>
        <Link to={tokenValid ? "/saved" : "/login"}>
          <div
            onMouseOver={() => setSelectedMenu("saved")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "saved"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "saved"
                  ? "#DEF2FF"
                  : selectedMenu === "saved"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Saved_img
              // fill={selectedMenu === "saved" ? "#009EFC" : "#999999"}
              stroke={
                window.location.href.split("/").pop() === "saved"
                  ? "#009EFC"
                  : selectedMenu === "saved"
                  ? "#009EFC"
                  : "#999999"
              }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "saved"
                    ? "#009EFC"
                    : selectedMenu === "saved"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Saved
            </div>
          </div>
        </Link>
        <Link to={tokenValid ? "/brain" : "/login"}>
          <div
            onMouseOver={() => setSelectedMenu("brain")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "brain"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "brain"
                  ? "#DEF2FF"
                  : selectedMenu === "brain"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Saved_img
              // fill={selectedMenu === "saved" ? "#009EFC" : "#999999"}
              stroke={
                window.location.href.split("/").pop() === "brain"
                  ? "#009EFC"
                  : selectedMenu === "brain"
                  ? "#009EFC"
                  : "#999999"
              }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "brain"
                    ? "#009EFC"
                    : selectedMenu === "brain"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Brain
            </div>
          </div>
        </Link>
        <div
          onClick={(e) => (tokenValid ? "" : navigate("/"))}
          onMouseOver={() => setSelectedMenu("chats")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "chats" ? "#DEF2FF" : "",
          }}
        >
          <Chats_img
            fill={selectedMenu === "chats" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "chats" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Chats
          </div>
        </div>

        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("conn")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "conn" ? "#DEF2FF" : "",
          }}
        >
          <Conn_img
            fill={selectedMenu === "conn" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "conn" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Connections
          </div>
        </div>
        <Link to={`${localStorage.getItem("actual_username")}`}>
          <div
            onMouseOver={() => setSelectedMenu("profile")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "profile"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background: tokenValid
                ? location.pathname.includes(
                    localStorage.getItem("actual_username")
                  )
                  ? "#DEF2FF"
                  : location.pathname.includes(
                      localStorage.getItem("actual_username")
                    )
                  ? "#DEF2FF"
                  : ""
                : localStorage.getItem("selected_user_email") === userEmail
                ? "#DEF2FF"
                : "",
            }}
          >
            <Profile_img
              fill={
                tokenValid
                  ? location.pathname.includes(
                      localStorage.getItem("actual_username")
                    )
                    ? "#009EFC"
                    : selectedMenu === localStorage.getItem("actual_username")
                    ? "#009EFC"
                    : "#999999"
                  : localStorage.getItem("selected_user_email") === userEmail
                  ? "#009EFC"
                  : "#999999"
              }
              stroke="none"
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color: tokenValid
                  ? location.pathname.includes(
                      localStorage.getItem("actual_username")
                    )
                    ? "#009EFC"
                    : location.pathname.includes(
                        localStorage.getItem("actual_username")
                      )
                    ? "#009EFC"
                    : "#999999"
                  : localStorage.getItem("selected_user_email") === userEmail
                  ? "#009EFC"
                  : "",
                paddingLeft: "10px",
              }}
            >
              Profile
            </div>
          </div>
        </Link>
        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("subs")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "subs" ? "#DEF2FF" : "",
          }}
        >
          <Subs_img
            fill={selectedMenu === "subs" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "subs" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Subscriptions
          </div>
        </div>

        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("vaults")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "vaults" ? "#DEF2FF" : "",
          }}
        >
          <Vaults_img
            fill={selectedMenu === "vaults" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "vaults" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Vaults
          </div>
        </div>

        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("drops")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "drops" ? "#DEF2FF" : "",
          }}
        >
          <Drops_img
            fill={selectedMenu === "drops" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "drops" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Drops
          </div>
        </div>

        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("interactionss")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "interactionss" ? "#DEF2FF" : "",
          }}
        >
          <Interactions_img
            fill={selectedMenu === "interactionss" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "interactionss" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Interactions
          </div>
        </div>
        {window.innerWidth > 900 ? (
          ""
        ) : (
          <Button
            block
            type="primary"
            size="large"
            onClick={(e) => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Logout
          </Button>
        )}
      </Space>
    </Col>
  ) : (
    <Col
      span={
        window.location.pathname.replace(/^\/([^\/]*).*$/, "$1") || tokenValid
          ? 4
          : 4
      }
      style={{
        // background: "#E5E5E5",
        padding: "10px",
        paddingTop: "20px",
        height: "90vh",
        borderRight: "0.5px solid #EDEDED",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Link to={tokenValid ? "/" : "/"}>
          <div
            onMouseOver={() => setSelectedMenu("home")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "home"
                ? setSelectedMenu(null)
                : ""
            }
            // onClick={(e) => setTrendClicked(false)}

            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "home"
                  ? "#DEF2FF"
                  : selectedMenu === "home"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Home_img
              fill={
                window.location.href.split("/").pop() === "home"
                  ? "#009EFC"
                  : selectedMenu === "home"
                  ? "#009EFC"
                  : "#999999"
              }
              stroke="none"
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "home"
                    ? "#009EFC"
                    : selectedMenu === "home"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Pulse
            </div>
          </div>
        </Link>
        <Link to={tokenValid ? "/saved" : "/login"}>
          <div
            onMouseOver={() => setSelectedMenu("saved")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "saved"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "saved"
                  ? "#DEF2FF"
                  : selectedMenu === "saved"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Saved_img
              // fill={selectedMenu === "saved" ? "#009EFC" : "#999999"}
              stroke={
                window.location.href.split("/").pop() === "saved"
                  ? "#009EFC"
                  : selectedMenu === "saved"
                  ? "#009EFC"
                  : "#999999"
              }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "saved"
                    ? "#009EFC"
                    : selectedMenu === "saved"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Saved
            </div>
          </div>
        </Link>
        <Link to={tokenValid ? "/brain" : "/login"}>
          <div
            onMouseOver={() => setSelectedMenu("brain")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "brain"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background:
                window.location.href.split("/").pop() === "brain"
                  ? "#DEF2FF"
                  : selectedMenu === "brain"
                  ? "#DEF2FF"
                  : "",
            }}
          >
            <Saved_img
              // fill={selectedMenu === "saved" ? "#009EFC" : "#999999"}
              stroke={
                window.location.href.split("/").pop() === "brain"
                  ? "#009EFC"
                  : selectedMenu === "brain"
                  ? "#009EFC"
                  : "#999999"
              }
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color:
                  window.location.href.split("/").pop() === "brain"
                    ? "#009EFC"
                    : selectedMenu === "brain"
                    ? "#009EFC"
                    : "#999999",
                paddingLeft: "10px",
              }}
            >
              Brain
            </div>
          </div>
        </Link>
        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("chats")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "chats" ? "#DEF2FF" : "",
          }}
        >
          <Chats_img
            fill={selectedMenu === "chats" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "chats" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Chats
          </div>
        </div>

        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("conn")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "conn" ? "#DEF2FF" : "",
          }}
        >
          <Conn_img
            fill={selectedMenu === "conn" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "conn" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Connections
          </div>
        </div>
        <Link to="/login">
          <div
            onMouseOver={() => setSelectedMenu("profile")}
            onMouseOut={() =>
              window.location.href.split("/").pop() !== "profile"
                ? setSelectedMenu(null)
                : ""
            }
            className="nav-item"
            style={{
              background: tokenValid
                ? location.pathname.includes(
                    localStorage.getItem("actual_username")
                  )
                  ? "#DEF2FF"
                  : location.pathname.includes(
                      localStorage.getItem("actual_username")
                    )
                  ? "#DEF2FF"
                  : ""
                : localStorage.getItem("selected_user_email") === userEmail
                ? "#DEF2FF"
                : "",
            }}
          >
            <Profile_img
              fill={selectedMenu === "profile" ? "#009EFC" : "#999999"}
              stroke="none"
              style={{ height: 25, width: 25 }}
            />
            <div
              className="nav-menu-text"
              style={{
                color: selectedMenu === "profile" ? "#009EFC" : "#999999",
                paddingLeft: "10px",
              }}
            >
              Profile
            </div>
          </div>
        </Link>
        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("subs")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "subs" ? "#DEF2FF" : "",
          }}
        >
          <Subs_img
            fill={selectedMenu === "subs" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "subs" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Subscriptions
          </div>
        </div>

        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("vaults")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "vaults" ? "#DEF2FF" : "",
          }}
        >
          <Vaults_img
            fill={selectedMenu === "vaults" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "vaults" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Vaults
          </div>
        </div>

        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("drops")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "drops" ? "#DEF2FF" : "",
          }}
        >
          <Drops_img
            fill={selectedMenu === "drops" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "drops" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Drops
          </div>
        </div>

        <div
          onClick={(e) => (tokenValid ? "" : navigate("/login"))}
          onMouseOver={() => setSelectedMenu("interactions")}
          onMouseOut={() => setSelectedMenu(null)}
          className="nav-item"
          style={{
            background: selectedMenu === "interactions" ? "#DEF2FF" : "",
          }}
        >
          <Interactions_img
            fill={selectedMenu === "interactions" ? "#009EFC" : "#999999"}
            stroke="none"
            style={{ height: 25, width: 25 }}
          />
          <div
            className="nav-menu-text"
            style={{
              color: selectedMenu === "interactions" ? "#009EFC" : "#999999",
              paddingLeft: "10px",
            }}
          >
            Interactions
          </div>
        </div>
      </Space>
    </Col>
  );
};

export default MenuSidebar;
