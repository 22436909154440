import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Col,
  Modal,
  Row,
  Tabs,
  Tooltip,
  Button,
  message,
  Progress,
  Card,
  Spin,
  Input,
  Switch,
  Radio,
} from "antd";
import AWS from "aws-sdk";
import { MyContext } from "../Context";
import { useNavigate } from "react-router-dom";
import {
  CameraOutlined,
  HomeOutlined,
  InboxOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import io from "socket.io-client";
import { sign } from "jsonwebtoken";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import bgImg from "../img/cover.jpg";

import { customAlphabet } from "nanoid";
import { AddOutlined } from "@material-ui/icons";

let url;
if ("test1" === "test") {
  url = "http://localhost:3232/";
  // url = "https://devstagebrainb.pulse.stream/";
} else {
  // url = "https://brain.gxnitrous.com/";
  url = "https://drivetest.globalxchange.io/";
}

//"http://localhost:3237"
const socket = io(
  "test1" === "test"
    ? "https://devstagebrains.pulse.stream"
    : "https://drivetestsock.pulse.stream",
  {
    // extraHeaders: {
    //   inf: "6ed22891bac6a1abd3f277c8cc97f170",
    //   mbid: "13d88b72d05effdc99a79bfe6c8ca589",
    //   mbuserid: "d9d575a688b5db6ed62632bde99bf4e3",
    // },
  }
);

socket.request = function request(type, data = {}) {
  return new Promise((resolve, reject) => {
    socket.emit(type, data, (data) => {
      if (!data.status) {
        resolve(data);
      } else {
        resolve(data);
      }
    });
  });
};

const chunk_size = 2000000;

const { TabPane } = Tabs;
const { Meta } = Card;
const { TextArea } = Input;
const Brain = () => {
  const navigate = useNavigate();
  var tempFiles = [];
  const {
    tokenValid,
    trendClicked,
    getPostsOfTrend,
    resTrendPosts,
    userInfo,
    userEmail,
    mySavedPosts,
    maxStorageLimit,
  } = useContext(MyContext);
  const nanoid = customAlphabet("23456789abcdefghijklmnopqrstuvwxyz", 15);
  const S3_BUCKET = "connections-app-dev";
  const [selectedMenu, setSelectedMenu] = useState("all");
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadFinished, setUploadFinished] = useState(false);
  const [profiledVideos, setProfiledVideos] = useState([]);
  const [dup_accepted_files, set_dup_accepted_files] = useState([]);
  const [quick, setQuick] = useState(false);

  const [fileFolderData, setfileFolderData] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [spaceUsedObject, setSpaceUsedObject] = useState(null);

  const [path, setPath] = useState("root/ConnectionApp/");
  const [quickImg, setQuickImg] = useState(false);

  const [allVideos, setAllVideos] = useState([]);

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailCloudfronturl, setThumbnailCloudfronturl] = useState("");

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});

  const [visibleMetadataModal, setVisibleMetadataModal] = useState(false);

  const [activeKey, setActiveKey] = useState(0);
  const [query, setQuery] = useState("");

  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop: (acceptedFiles) => {
      set_dup_accepted_files([...acceptedFiles]);
    },
    noKeyboard: true,
    accept:
      "video/avi, video/msvideo, video/x-msvideo, video/mp4, video/x-flv, video/quicktime, image/bmp, text/css, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/epub+zip, application/gzip, image/gif, text/html, image/vnd.microsoft.icon, image/jpeg, text/javascript, application/json, audio/mpeg, image/png, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, image/svg+xml, application/x-tar, image/tiff, text/plain, audio/wav, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, application/x-7z-compressed, application/vnd.ms-excel,	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .apk, .zip",
  });

  AWS.config.update({
    accessKeyId: "AKIAUEO6Q6T7E5XJOAQR",
    secretAccessKey: "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",
    //   accessKeyId: "AKIAUEO6Q6T7BHZWWS6F",
    //   secretAccessKey: "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",
  });

  const myBucket = new AWS.S3({
    params: { Bucket: "connections-app-dev" },
    region: "ap-south-1",
  });

  const createFolder = () => {
    // message.destroy();
    // message.loading("Creating Folder In Progess", 0);
    console.log(fileFolderData);
    let temp = {
      path: "root/",
      name: "ConnectionApp",
      folder: true,
    };
    console.log(temp);

    let requiredList = fileFolderData.filter((item) => {
      return item.path === "root/";
    });

    let x = requiredList.find((o) => o.name == "ConnectionApp");
    console.log(x);

    if (x === undefined) {
      axios
        .post(url + "folder/create-folder", {
          //username: "karthik",
          email: localStorage.getItem("user_account"),
          token: localStorage.getItem("token"),
          folderName: "ConnectionApp",
          path: "root/",
        })
        .then((res) => {
          console.log(res.data);
          //   if (res.data.status) {
          //     message.destroy();
          //     message.success("Folder Creation Success", 3);
          //     setfileFolderData([...fileFolderData, temp]);
          //   } else {
          //     message.destroy();
          //     message.error("Folder Creation Failed", 3);
          //   }
        });
    } else {
      message.destroy();
      message.warning("Folder Name Already Exists", 3);
    }
  };

  useEffect(() => {
    // console.log("next_chunk_content useeffect");
    socket.on("next_chunk_content", async (data) => {
      console.log(data);

      let already_uploaded_data = data.uploaded_bytes;
      let required_file = acceptedFiles.find(
        (find_file) => find_file.name === data.file_name
      );
      // console.log(required_file);

      let slice_end =
        already_uploaded_data + chunk_size > required_file.size
          ? required_file.size
          : already_uploaded_data + chunk_size;
      let file = required_file.slice(already_uploaded_data, slice_end);
      // console.log(slice_end);
      // console.log(file);

      const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf";

      const token = sign(
        {
          file_name: required_file.name,
          file_size: required_file.size,
          email: localStorage.getItem("user_account"),
        },
        secret,
        {
          algorithm: "HS512",
          expiresIn: 240,
          issuer: "gxjwtenchs512",
        }
      );
      let src = "content";
      let resp = await axios.post(
        url +
          `file/large_file_upload?username=karthik&email=${localStorage.getItem(
            "user_account"
          )}&path=${path}&token=${token}&file_name=${
            required_file.name
          }&src=${src}`,
        file,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "total-file-size": required_file.size,
          },
        }
      );
      // console.log(resp);

      if (resp.data.status === false) {
        message.error("File Size Exceeds Your Free Quota", 4);
        setUploadStarted(false);
        setUploadFinished(false);
        setVisibleUpload(false);
        removeAll();
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }

      if (resp.data.payload.message === "Completely Uploaded") {
        console.log("cnt", "   completed", required_file.name);
        if (acceptedFiles.length === 1) {
          console.log("balllooooooo");
          setUploadFinished(true);
          message.success("File Upload Success", 3);
          setTimeout(() => {
            setUploadStarted(false);
            setUploadFinished(false);
            setVisibleUpload(false);
            removeAll();
            // window.location.reload();
            getAllVideos();
          }, 2000);
        } else {
          let temp_accepted_files = [...dup_accepted_files];
          let uploaded_count = 1;
          for (const temp_file of temp_accepted_files) {
            if (temp_file.name === required_file.name) {
              console.log("cnt", "adding true", "     ", temp_file.name);
              temp_file["finished"] = true;
            } else {
              if (temp_file.finished) uploaded_count++;
            }
          }
          set_dup_accepted_files([...temp_accepted_files]);
          console.log("cnt", dup_accepted_files);
          console.log("cnt", uploaded_count);
          if (uploaded_count === acceptedFiles.length) {
            setUploadFinished(true);
            message.success("File Upload Success", 3);
            setTimeout(() => {
              setUploadStarted(false);
              setUploadFinished(false);
              setVisibleUpload(false);
              removeAll();
              //   window.location.reload();
              getAllVideos();
            }, 2000);
          }
        }
      }
    });
    return () => {
      console.log("offing content");
      socket.off("next_chunk_content");
    };
  }, [acceptedFiles, dup_accepted_files]);

  useEffect(() => {
    userchk();
    createFolder();
  }, []);

  const userchk = () => {
    let email_from_storage = localStorage.getItem("user_account");
    // setEmail(email_from_storage);
    let token_from_storage = localStorage.getItem("token");
    // setToken(token_from_storage);
    let username_from_storage = localStorage.getItem("uusseerrname");
    // setUsername(username_from_storage);

    axios
      .post(`${url}account/registration-check`, {
        email: email_from_storage,
        token: token_from_storage,
      })
      .then((res) => {
        if (res.data.status) {
          //   setRegisterFlag(res.data.payload);
          if (res.data.payload) {
            axios
              .post(
                url + "folder/list-contents",
                {
                  email: email_from_storage,
                  token: token_from_storage,
                }
                // {
                //   headers: {
                //     inf: "6ed22891bac6a1abd3f277c8cc97f170",
                //     mbid: "13d88b72d05effdc99a79bfe6c8ca589",
                //     mbuserid: "6f794dd81e96b34539027ead48e8f32f",
                //   },
                // }
              )
              .then((res) => {
                let all_data = [...res.data];
                setStorageData([...all_data[all_data.length - 1]]);
                all_data.pop();
                setfileFolderData([...all_data]);
              });

            axios
              .post(url + "folder/total-size", {
                email: email_from_storage,
                token: token_from_storage,
              })
              .then((res) => {
                // console.log(res.data.payload);
                setSpaceUsedObject({ ...res.data.payload });
              });

            // axios
            //   .post(url + "account/storage-limit", {
            //     email: email_from_storage,
            //     token: token_from_storage,
            //   })
            //   .then((res) => {
            //     // console.log(res.data.payload);
            //     setMaxStorageLimit(res.data.payload * 10 ** 9);
            //   });
          }
        }
      });
  };

  const getAllVideos = () => {
    axios
      .get(`https://vod-backend.globalxchange.io/get_file_list`, {
        headers: {
          email: localStorage.getItem("user_account"),
          token: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data) {
          const sorted = res.data.payload.sort((a, b) =>
            a.timestamp < b.timestamp ? 1 : -1
          );
          setAllVideos(sorted);
        }

        getMyProfiledVideos();
      });
  };

  useEffect(() => {
    getAllVideos();
  }, []);

  let filteredAllVideos = allVideos.filter((item) => {
    const lowquery = query.toLowerCase();
    return item.name.toLowerCase().indexOf(lowquery) >= 0;
  });

  let filteredProfiledVideos = profiledVideos.filter((item) => {
    const lowquery = query.toLowerCase();
    return item.title.toLowerCase().indexOf(lowquery) >= 0;
  });

  const getMyProfiledVideos = () => {
    // setRefetchFilteredVideos(true);
    axios
      .post(
        `https://vod-backend.globalxchange.io/get_my_profiled_video_list`,
        {},
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // setRefetchFilteredVideos(false);
        if (res.data.status) {
          console.log("profiled", res.data.payload);
          setProfiledVideos(res.data.payload);
        } else {
          message.error("Fetching Profiled Video Failed", 3);
        }
      });
  };

  const get_file_status = async () => {
    console.log("inside get accept files");
    // let l = [...acceptedFiles];
    // console.log(l);
    // set_dup_accepted_files([...l]);
    let chosen_file_name_set = new Set();

    let totalUploadingFilesSize = 0;

    for (let item of acceptedFiles) {
      totalUploadingFilesSize += item.size;
      let modified_filename = item.name.replace(/[^0-9a-zA-Z-_.]/g, "");
      tempFiles.push(modified_filename);
      // console.log(item);
      chosen_file_name_set.add(modified_filename + item.size.toString());
    }
    console.log(tempFiles, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    if (quick) {
      localStorage.setItem("quickImage", JSON.stringify(tempFiles)); // make it conditional
    }

    let existing_file_name_set = new Set();
    for (let tmp_file of fileFolderData) {
      if (tmp_file.path === path && !tmp_file.folder) {
        console.log(tmp_file);
        let modified_filename = tmp_file.name.replace(/[^0-9a-zA-Z-_.]/g, "");
        existing_file_name_set.add(
          modified_filename + tmp_file.file_size.toString()
        );
      }
    }

    let intersection = new Set(
      [...existing_file_name_set].filter((x) => chosen_file_name_set.has(x))
    );
    console.log(intersection);
    if (intersection.size !== 0) {
      message.error(
        <b>One or More Files With Same Name Exists In Current Path</b>,
        5
      );
      setUploadStarted(false);
      setUploadFinished(false);
      setVisibleUpload(false);
      removeAll();
    } else {
      if (
        totalUploadingFilesSize +
          spaceUsedObject.storage_size +
          spaceUsedObject.trash_size >
        maxStorageLimit
      ) {
        message.error("File Size Exceeded Your Storage Quota", 4);
        setUploadStarted(false);
        setUploadFinished(false);
        setVisibleUpload(false);
        removeAll();
      } else {
        setUploadStarted(true);
        for (let one_file of acceptedFiles) {
          console.log(one_file.name);

          const data = await socket.request("file_upload_status", {
            email: localStorage.getItem("user_account"),
            path: path,
            size: one_file.size,
            file_name: one_file.name,
          });

          if (data.status === "file is present") {
            message.error(
              <b>One or More Files With Same Name Exists In Current Path</b>,
              5
            );
            setUploadStarted(false);
            setUploadFinished(false);
            setVisibleUpload(false);
            removeAll();
            break;
          } else {
            let already_uploaded_data = data.payload;
            // const formData = new FormData();

            let slice_end =
              already_uploaded_data + chunk_size > one_file.size
                ? one_file.size
                : already_uploaded_data + chunk_size;
            let file = one_file.slice(already_uploaded_data, slice_end);
            // console.log(slice_end);
            // console.log(file);
            let src = "content";

            const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf";
            const token = sign(
              {
                file_name: one_file.name,
                file_size: one_file.size,
                email: localStorage.getItem("user_account"),
              },
              secret,
              {
                algorithm: "HS512",
                expiresIn: 240,
                issuer: "gxjwtenchs512",
              }
            );

            let resp = await axios.post(
              url +
                `file/large_file_upload?username=karthik&email=${localStorage.getItem(
                  "user_account"
                )}&path=${path}&token=${token}&file_name=${
                  one_file.name
                }&src=${src}`,
              file,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "total-file-size": one_file.size,
                },
              }
            );
            console.log(resp, "lkwndlcnwle");
            if (resp.data.status === false) {
              message.error("File Size Exceeds Your Free Quota", 4);
              setUploadStarted(false);
              setUploadFinished(false);
              setVisibleUpload(false);
              removeAll();
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            }
            if (resp.data.payload.message === "Completely Uploaded") {
              if (acceptedFiles.length === 1) {
                console.log("balllooooooo");
                setUploadFinished(true);
                message.success("File Upload Success", 3);
                setTimeout(() => {
                  setUploadStarted(false);
                  setUploadFinished(false);
                  setVisibleUpload(false);
                  removeAll();
                  //   window.location.reload();
                  getAllVideos();
                }, 2000);
              } else {
                console.log("cnt", "kkk");
                let temp_accepted_files = [...dup_accepted_files];
                let uploaded_count = 1;
                for (const temp_file of temp_accepted_files) {
                  if (temp_file.name === one_file.name) {
                    temp_file["finished"] = true;
                  } else {
                    if (temp_file.finished) uploaded_count++;
                  }
                }
                console.log("cnt", uploaded_count);
                set_dup_accepted_files([...temp_accepted_files]);

                if (uploaded_count === acceptedFiles.length) {
                  setUploadFinished(true);
                  message.success("File Upload Success", 3);
                  setTimeout(() => {
                    setUploadStarted(false);
                    setUploadFinished(false);
                    setVisibleUpload(false);
                    removeAll();
                    window.location.reload();
                  }, 2000);
                }
              }
            }
          }
        }
      }
    }
    setQuickImg(false);
  };

  const removeAll = () => {
    console.log("removeAll...");
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current != null) {
      inputRef.current.value = "";
    } else {
    }
    console.log(acceptedFiles);
  };

  const onThumbnailChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setThumbnail(e.target.result);
        // this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      uploadThumbnail(event.target.files[0]);
    }
  };

  const uploadThumbnail = async (file) => {
    setLoading(true);
    let fileExtention = file.name.split(".")[1];
    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: `test/${nanoid()}.${fileExtention}`,
      ContentType: `image/${fileExtention}`,
    };

    myBucket.upload(params, function (err, data) {
      if (!err) {
        //   console.log(err, data, "hdcjhwcjhwv");
        // console.log(`https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`);
        message.success("File Upload Success", 2);
        let url = `https://ik.imagekit.io/0tt94zrgb5v/dev/${data.key}`;

        setThumbnailCloudfronturl(url);

        // insertToEditor(url);
        setLoading(false);
      } else {
        console.log(err);
        setLoading(false);
        message.destroy();
        message.error("File Upload Failed", 2);
      }
    });
  };

  const addtoVideoPost = (item) => {
    let obj = profiledVideos.find((o) => o.video_id === item.id);

    if (obj) {
      message.info("You have already added metadata for this video");
      setVisibleMetadataModal(false);
    } else {
      message.destroy();
      setSelectedVideo(item);
      setVisibleMetadataModal(true);
    }
  };

  const addMetadata = (item) => {
    axios
      .post(
        `https://vod-backend.globalxchange.io/new_profiled_video`,
        {
          title: title,
          subtitle: subtitle,
          description: videoDescription,
          thumbnail: thumbnailCloudfronturl,
          path: selectedVideo.path,
          name: selectedVideo.name,
          folder: selectedVideo.folder,
          file_type: selectedVideo.file_type,
          file_size: selectedVideo.file_size,
          run_time: selectedVideo.run_time,
          video_id: selectedVideo.id,
          email: selectedVideo.email,
          user_drive_id: selectedVideo.user_drive_id,
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          message.success("Video Profiling Success", 3);
          setVisibleMetadataModal(false);
          setTitle("");
          setSubtitle("");
          setVideoDescription("");
          setThumbnailCloudfronturl("");
          setThumbnail("");
          getMyProfiledVideos();
        } else {
          message.error("Video Profiling Failed", 3);
        }
      });
  };

  const files = acceptedFiles.map((file, index) => (
    <p key={file.path} style={{ marginBottom: "5px" }}>
      {/* <Paragraph
      style={{ fontWeight: "bold" }}
      editable={{ onChange: (value) => editFileName(value, index) }}
    >
      {file.path}
    </Paragraph> */}
      <span style={{ fontWeight: "bold" }}>{file.name}</span>
      &nbsp;- &nbsp;
      {(file.size / 10 ** 6).toFixed(3)} MB &nbsp;&nbsp;
      {/* <Icon
      type="edit"
      // style={{ color: "red" }}
      onClick={editFileName(file)}
    ></Icon> */}
    </p>
  ));

  const makeVideoPublic = (item) => {
    axios
      .post(
        `https://vod-backend.globalxchange.io/update_profiled_video_data`,
        {
          title: item.title,
          subtitle: item.subtitle,
          description: item.description,
          thumbnail: item.thumbnail,
          video_id: item.video_id,
          public: true,
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          getMyProfiledVideos();
        } else {
          message.error("Video Profiling Failed", 3);
        }
      });
  };

  const conditionalVideos = () => {
    if (selectedMenu === "all") {
      return (
        <div style={{ overflowY: "scroll", height: "65vh", width: "90%" }}>
          {filteredAllVideos.length > 0 ? (
            filteredAllVideos.map((item) => {
              if (item.file_type === "video") {
                return (
                  <div style={{ padding: "10px" }}>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid rgba(250, 250, 250, 1)",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <img
                          //   style={{ marginTop: "-5px" }}
                          src={require(`../img/file-types/${item.file_type}.png`)}
                          alt=""
                          width="20px"
                        />
                        &nbsp;&nbsp;{item.name}
                      </div>
                      <Button
                        type="primary"
                        onClick={(e) => addtoVideoPost(item)}
                      >
                        Make Public
                      </Button>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "65vh",
              }}
            >
              <Spin />
            </div>
          )}
        </div>
      );
    } else if (selectedMenu === "public") {
      return (
        <div
          style={{
            overflowY: "scroll",
            height: "65vh",
          }}
        >
          {filteredProfiledVideos.map((item) => {
            return (
              <div
                style={{
                  // width: 300,
                  // margin: "20px",
                  // border: "1px solid lightgray",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "20px",
                  width: "70vw",
                }}
              >
                <div>
                  <img
                    src={bgImg}
                    alt=""
                    width="300px"
                    height="170px"
                    style={{ borderRadius: "10px" }}
                  />
                </div>

                <div style={{ paddingLeft: "20px" }}>
                  <h4>{item.title}</h4>
                  <p>{item.subtitle}</p>
                  <p>
                    {item.description ? item.description.substring(0, 200) : ""}
                  </p>
                </div>

                {/* <Meta title={item.title} description={item.subtitle} /> */}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      <Col span={20}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //   alignItems: "center",
            padding: "30px",
            width: "100%",
          }}
        >
          {/* <Button
            onClick={(e) => setVisibleUpload(true)}
            shape="round"
            icon={<AddOutlined />}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              background: "#009EFC",
              fontWeight: "bold",
              height: "50px",
            }}
            // size={size}
          >
            <span style={{}}>Upload Video</span>
          </Button> */}

          <div>
            <Input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              suffix={<SearchOutlined />}
              placeholder="Search Video"
              style={{ width: "500px", borderRadius: "20px", height: "50px" }}
            />
          </div>

          <Radio.Group
            value={selectedMenu}
            onChange={(e) => setSelectedMenu(e.target.value)}
          >
            <Radio.Button
              onClick={(e) => setQuery("")}
              value="all"
              style={{
                background: selectedMenu === "all" ? "#009EFC" : "",
                color: selectedMenu === "all" ? "white" : "#999999",
                fontWeight: "bold",
                borderRadius: "20px 0px 0px 20px",
                height: "50px",
                paddingTop: "10px",
              }}
            >
              All Videos
            </Radio.Button>
            <Radio.Button
              onClick={(e) => setQuery("")}
              value="public"
              style={{
                background: selectedMenu === "public" ? "#009EFC" : "",
                color: selectedMenu === "public" ? "white" : "#999999",
                fontWeight: "bold",
                borderRadius: "0px 20px 20px 0px",
                height: "50px",
                paddingTop: "10px",
              }}
            >
              Public Videos
            </Radio.Button>
          </Radio.Group>

          {/* <div
            onClick={(e) => setSelectedMenu("all")}
            className={
              selectedMenu === "all" ? "nav-item1-selected" : "nav-item1"
            }
            style={{
              background: selectedMenu === "all" ? "#DEF2FF" : "",
              margin: "20px",
            }}
          >
            <span className="nav-menu-text1">All Videos</span>
          </div>
          <div
            onClick={(e) => setSelectedMenu("private")}
            className={
              selectedMenu === "private" ? "nav-item1-selected" : "nav-item1"
            }
            style={{
              background: selectedMenu === "private" ? "#DEF2FF" : "",
              margin: "20px",
            }}
          >
            <span className="nav-menu-text1">Private Videos</span>
          </div>
          <div
            onClick={(e) => setSelectedMenu("public")}
            className={
              selectedMenu === "public" ? "nav-item1-selected" : "nav-item1"
            }
            style={{
              background: selectedMenu === "public" ? "#DEF2FF" : "",
              margin: "20px",
            }}
          >
            <span className="nav-menu-text1">Public Videos</span>
          </div> */}
        </div>

        <div style={{ padding: "0px 20px" }}>{conditionalVideos()}</div>
        {/* <div style={{ padding: "20px" }}>
          <Tabs
            defaultActiveKey="0"
            tabPosition="right"
            style={{ height: "150px" }}
          >
            <TabPane
              onClick={(e) => setActiveKey(0)}
              tab={
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: activeKey === "0" ? "green" : "red",
                  }}
                >
                  All Videos
                </div>
              }
              key="1"
            >
              All Videos
            </TabPane>
            <TabPane
              onClick={(e) => setActiveKey(1)}
              tab={
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Public Videos
                </div>
              }
              key="2"
            >
              Public Videos
            </TabPane>
          </Tabs>
        </div> */}

        <div className="fixed-widget-bottm-right ">
          <Tooltip placement="left" title="Upload Video">
            <Avatar
              onClick={(e) => setVisibleUpload(true)}
              className="img-animate"
              size={50}
              style={{ backgroundColor: "#009efc" }}
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </div>
      </Col>

      {/* Upload Video */}

      <Modal
        destroyOnClose
        footer={null}
        title="Upload Video"
        visible={visibleUpload}
        // closable={!uploadStarted}
        // onOk={this.handleOk}
        onCancel={(e) => {
          if (!uploadStarted) {
            setVisibleUpload(false);
            removeAll();
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {!uploadStarted ? (
            <>
              <div
                {...getRootProps({ className: "mydropzone" })}
                style={{
                  padding: "50px",
                  borderStyle: "dashed",
                  borderRadius: "10px",
                  borderWidth: "2px",
                  borderColor: "lightgray",
                  textAlign: "center",
                }}
              >
                <input {...getInputProps()} />
                <InboxOutlined
                  style={{ fontSize: "70px", color: "lightgray" }}
                />

                <p style={{ fontWeight: "500" }}>
                  Drag 'n' drop a file here, or click to select a file
                </p>
              </div>
              <div style={{ marginTop: "10px", marginLeft: "10px" }}>
                {/* <h4>Files</h4> */}
                {files}
              </div>
              <Button
                disabled={acceptedFiles.length === 0}
                block
                type="primary"
                style={{ marginTop: "15px" }}
                // onClick={checkFilename}
                onClick={get_file_status}
              >
                UPLOAD FILE
              </Button>{" "}
            </>
          ) : !uploadFinished ? (
            <>
              <Spin size="large" />
              {/* <Loader type="Oval" color="#00BFFF" height={100} width={100} /> */}
              <span
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Uploading
              </span>
              {files}
            </>
          ) : (
            <Progress type="circle" percent={100} />
          )}
        </div>
      </Modal>

      {/* Add Metadata to videos */}

      <Modal
        centered
        title={editing ? "Update Metadata to Video" : "Add Metadata of Video"}
        visible={visibleMetadataModal}
        // onOk={!loading ? addMetadata : ""}
        onCancel={(e) => setVisibleMetadataModal(false)}
        footer={null}
        bodyStyle={{ padding: "20px" }}
      >
        <Input
          placeholder="Enter Video Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Input
          style={{ marginTop: "10px" }}
          placeholder="Enter Video Subtitle"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
        />
        <TextArea
          value={videoDescription}
          onChange={(e) => setVideoDescription(e.target.value)}
          rows={4}
          placeholder="Enter Video Description"
          style={{ marginBottom: "10px", marginTop: "10px" }}
        />
        <div
          style={{
            backgroundImage: `url(${thumbnail})`,
            backgroundSize: "cover",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: thumbnail ? "none" : "dashed",
            borderWidth: "1px",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <label for="image">
            <input
              accept="image/*"
              // value={coverImage}
              // onChange={e =>
              //   setCoverImage(URL.createObjectURL(e.target.files[0]))
              // }
              onChange={onThumbnailChange}
              type="file"
              name="image"
              id="image"
              style={{ display: "none" }}
            />
            <CameraOutlined
              style={{
                fontSize: "40px",
                display: thumbnail ? "none" : "block",
                color: "lightgray",
              }}
            />

            {/* <Avatar
                    style={{
                      backgroundColor: "white",
                      zIndex: "0",
                      color: "black",
                      margin: "20px",
                      opacity: 1,
                      cursor: "pointer",
                      marginTop: "-30px"
                    }}
                    icon="camera"
                    size={80}
                  /> */}
          </label>
        </div>
        <Button onClick={addMetadata} loading={loading} type="primary">
          Submit
        </Button>
      </Modal>
    </>
  );
};

export default Brain;
